import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const translation_en = require("./locales/en/translation.json")
const translation_de = require("./locales/de/translation.json")

i18next
	// detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	.init({
    resources: {
      en: { translation: translation_en },
      de: { translation: translation_de }
    },
    //lng: 'en',
    fallbackLng: 'de',
    debug: true,
    keySeparator: false,
        
    saveMissing: true, // send not translated keys to endpoint
  
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
	});
	
	export default i18next;