<template>

		<b-form-checkbox switch class="mx-2 py-2" v-model="zulassung">
			<span class="sr-only">Switch für Zulassung</span> <span class="px-2"> {{receiptType.name}}</span>
		</b-form-checkbox> 

</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: ['libertas_center_id', 'receiptType'],
	computed:{
		...mapGetters(['lcRecords']),
		lcRecord(){ return this.lcRecords.find( w => w.libertas_center_id === this.libertas_center_id) },
		allowedReceiptTypes() {
			return (this.lcRecord && this.lcRecord.profile && this.lcRecord.profile.allowed_receipt_types)?
				this.lcRecord.profile.allowed_receipt_types:
				[]
		},
		zulassung: {
		get () {
			return this.allowedReceiptTypes.indexOf(this.receiptType.receipt_type_id) >= 0
		},
		async set (value) {
			const payload = { 
				libertas_center_id: this.libertas_center_id,
				allowed_receipt_types: this.allowedReceiptTypes, 
				receipt_type_id: this.receiptType.receipt_type_id,
				zulassung: value  }

			const result = await this.$store.dispatch('receiptTypeZulassung', payload)
			if(result.isRight){
				const resultPullUpdate = this.$store.dispatch('updateLcRecords') 
				if(resultPullUpdate.isLeft){
						this.$bvToast.toast(this.$t('common.network.issue.text'), {
          			title: this.$t('common.network.issue.title'),
								autoHideDelay: 3200,
								variant:'warning'
								})
				}
			}else{
				this.$bvToast.toast(this.$t('lcrecord.patch.failed'), {
          			title: this.$t('lcrecord.patch.failed.title'),
								autoHideDelay: 3200,
								variant:'warning'
				})
			}
		}
	}
		
	},
}
</script>

<style>

</style>