<template>
	
  <header class="navbar  navbar-light bg-light" v-bind:class="{'fixed-top' : fixedTop}">
    <a class=" navbar-brand" href="#" >
      <span class="mx-2" v-b-toggle.main-menu ><MenuIcon   /></span>
      <b-sidebar id="main-menu" shadow>
        <template #title >
          <Logo class="logo-header" /> {{$t("libertas")}}
        </template>
        <div class="px-3 py-2">
          <div>
          <b-nav small vertical :fill="true" class="text-left">
            <div class="dropdown-item clickableValue" v-if="isLoggedIn" @click="toHomeScreen();">
              {{$t("dashboard")}}
            </div>
            <div class="dropdown-item clickableValue" v-if="isLoggedIn && isAdminAccount" @click="toLcRecordsScreen()">
              {{$t("menu.administration")}}
            </div>
            <div class="dropdown-item clickableValue disabled" v-if="isLoggedIn" @click="toReceiptsScreen()">
              {{$t("menu.receipts")}}
            </div>

            <div class="dropdown-divider"></div>

            <div class="dropdown-item clickableValue" v-if="isLoggedIn" @click="toSettings()">
              <SettingsIcon /> {{$t("settings")}}
            </div>


            <div class="dropdown-divider"></div>
            <div class="dropdown-item clickableValue"  @click="werkeinstellungen()">
              <FactoryIcon /> {{$t("factory.settings")}}
              
            </div>
            <div class="dropdown-divider"></div>

            <div class="dropdown-item clickableValue" v-if="isLoggedIn" @click="toAbout()">
              <AboutIcon /> {{$t("about")}}
            </div>
          </b-nav>
          </div>
        </div>
      </b-sidebar>
      <div class="ml-2 d-inline-block text-left align-middle" @click="toHomeScreen(false)">

        <div v-if="isScreenLcRecords" class="mb-0 mt-0 h5">{{$t('lcrecords.header.1')}}</div>
        <div v-else class="mb-0 mt-0 h5">{{$t('app.name')}}</div>

        <div v-if="isScreenLcRecords"  class=" smart-size font-weight-light pt-0">{{$t('lcrecords.header.2')}}</div>
        <div v-else class="h6 font-weight-light pt-0">{{orgaName}}</div>
      </div>
    </a>
    <form class="form-inline my-2 my-lg-0">
      <SearchPanelLcRecords v-if="isScreenLcRecords" class="d-none d-md-block" />
      <div  v-else class="ml-1  d-inline-block logo">
        <Logo class="logo-header d-none d-sm-inline-block" />
      </div>
      
     
    </form>
  </header>
	
</template>
<script>
import { mapGetters } from 'vuex'
import SearchPanelLcRecords from './lcRecords/SearchPanel'
import MenuIcon from 'vue-material-design-icons/Menu.vue'
import AboutIcon from 'vue-material-design-icons/InformationOutline.vue'
import SettingsIcon from 'vue-material-design-icons/Cog.vue'
import FactoryIcon from 'vue-material-design-icons/Logout.vue'
import AccountIcon from 'vue-material-design-icons/AccountKeyOutline.vue'
import Logo from './Logo';
export default {
	computed:{
     ...mapGetters(['isScreenLcRecords', 'isSetupCompleted', 'kkJWT', 'orgaName', 'isAdminAccount']),
    isLoggedIn() { return this.kkJWT?true:false },
    fixedTop(){ return this.$store.getters.isHeaderFixedTop }
    
  },
  components:{ SearchPanelLcRecords, Logo, MenuIcon, AboutIcon, SettingsIcon, FactoryIcon, AccountIcon },
  methods:{
    async werkeinstellungen(){ 
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('factory.reset.confirm.text'), 
			{
          title: this.$t('restore.factory.settings'),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('reset.to.factory.ok'),
          cancelTitle: this.$t('cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
      this.$root.$emit('bv::toggle::collapse', 'main-menu')  
			if(!decision) return
		  this.$store.dispatch('resetApp') 
    },

    toHomeScreen: function(doToggle = true){
      this.$store.dispatch( 'showDashboard' )
      if(doToggle) this.$root.$emit('bv::toggle::collapse', 'main-menu')
      //this.$store.dispatch('updateDashboardData')
		},
    toLcRecordsScreen: function(){
      this.$store.dispatch( 'showLcRecords' )
      this.$root.$emit('bv::toggle::collapse', 'main-menu')
		},
    toSettings: function(){
      this.$store.dispatch( 'showSettings' )
      this.$root.$emit('bv::toggle::collapse', 'main-menu')
		},
    toImpressum: function(){
      this.$store.dispatch( 'showImpressum' )
      this.$root.$emit('bv::toggle::collapse', 'main-menu')
		},
    toPrivacyPolicy: function(){
      this.$store.dispatch( 'showPrivacyPolicy' )
      this.$root.$emit('bv::toggle::collapse', 'main-menu')
		},
    toAbout: function(){
      this.$store.dispatch( 'showAbout' )
      this.$root.$emit('bv::toggle::collapse', 'main-menu')
		},
  }
}
</script>
<style scoped>
.logo-header {
  width: 50px;
  height: 50px;
}
.bg-white {
  background-color: white !important;
}
.fixed-top { 
  z-index: 5; 
  padding-right: max(constraint(safe-area-inset-right), 16px);
  padding-right: max(env(safe-area-inset-right), 16px);
  padding-left: max(constraint(safe-area-inset-left), 16px);
  padding-left: max(env(safe-area-inset-left), 16px);
} 
@supports (padding-top: constant(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  body{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 76px);
  }
  .fixed-top{
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(var(--safe-area-inset-top) + 0px);
  }
}

</style>