import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'


import * as serviceWorker from './serviceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18Next from '@panter/vue-i18next';
import i18next from './i18n/i18n'

import './custom.scss'
import './app.css'

import store from './store'


// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueI18Next)
const i18n = new VueI18Next(i18next);

Vue.config.productionTip = false

const app = new Vue({
  render: h => h(App),
  store,
  i18n: i18n,
  beforeCreate() {
		this.$store.commit('setupAppRuntimeSettings');
  },
  methods:{
    onDeviceReady:function(){
      console.log("VueApp on deviceready")
      this.$store.commit('setupAppRuntimeSettings')
      if(window.cordova && 'browser' === window.cordova.platformId){
        serviceWorker.register()
      }
      this.$store.dispatch('setupAppRuntimeSettingsCopmleted')
    }
  }
}).$mount('#app')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(!window.cordova){
	serviceWorker.unregister();
}

document.addEventListener('deviceready', app.onDeviceReady);