<template>
	<div class="search-panel">
		<b-form @submit.stop.prevent class="bg-light">
					<b-form-group class="has-search">
    				<span class="form-control-feedback">
							<b-icon :animation="animationSearchIcon" :variant="variantSearchIcon"  scale="1.2" icon="search"></b-icon>
						</span>
						
							<b-icon v-if="searchString" class="form-control-feedback-back" scale="1.2" variant="primary" 
								icon="x" @click="resetSearch($event)"></b-icon>
							<datamatrix-scan-icon v-if="showIconScan" :size="18" @click="scanBarcode()"
								class="text-primary form-control-feedback-back" />
							<datamatrix-scan-icon v-if="showIconScanQRCode" :size="18" @click="scanQRCode()"
								class="text-primary form-control-feedback-back" />

      			<b-form-input ref="searchInput" class="search-input" @keyup="onInputKeyUp($event)" size="md" type="text" 
							:placeholder="$t('search.placeholder')" v-model="searchString" >
							<!-- @keyup="editOnKeyUp($event)" @blur="updateValue()" aria-describedby="input-help-block" > -->
						</b-form-input>
    			</b-form-group>
				</b-form>
				<QrCodeModal :id="id4Modal" :onBarcodeDetected="qrCodefound" 
						:subject="$t('searchpanel.qrcode.scan.subject')" 
						:title="$t('searchpanel.qrcode.scan.title')" />
	</div>
</template>

<script>
import DatamatrixScanIcon from 'vue-material-design-icons/DataMatrixScan'
import { defaultBarcodeScannerOptions } from '../../commons/cordovascan'
import { recognizeLoginBarcode } from '../../libertas/barcodeHandler'
import QrCodeModal from '../qrcodereader/QrCodeModal'
import { nanoid } from 'nanoid'
export default {
	data () {
    return {
			id4Modal: nanoid().substring(0, 9)
    }
  },
	components: { DatamatrixScanIcon, QrCodeModal },
	computed:{
		searchString: {
			get () {
				return this.$store.getters.lcRecordsSearchString
      },
      set (value) {
				this.$store.commit('lcRecordsSearchString', value)
				const loginBarcode = recognizeLoginBarcode(value)
				if(loginBarcode) this.$store.commit('lcRecordsSearchString', loginBarcode.login )
      }
		},

		showIconScan(){ return !this.searchString && this.$store.getters.isMobileAppMode },
		showIconScanQRCode(){ return !this.searchString && !this.$store.getters.isMobileAppMode },
		
		variantSearchIcon(){ return this.$store.getters.lcRecordsSearchString.length?'primary':'default'},
		animationSearchIcon(){ return this.$store.getters.lcRecordsSearchString.length?'throb':'none'}
	},
	methods:{
		onInputKeyUp(event) {
			if(event.keyCode === 27) this.$store.commit('lcRecordsSearchString', '') 
		},
		resetSearch(event) { 
			this.$store.commit('lcRecordsSearchString', '') 
			setTimeout(() => {
				if(this.$refs.searchInput) {
					this.$refs.searchInput.focus()
				}
				}, 80)
		},
		setSearchString(searchText) {
			this.$store.commit('lcRecordsSearchString', searchText)
			const loginBarcode = recognizeLoginBarcode(searchText)
			if(loginBarcode) this.$store.commit('lcRecordsSearchString', loginBarcode.login )
		},
		scanBarcode(){
			if( window.cordova ) {
				const setSearchString = this.setSearchString
				window.cordova.plugins.barcodeScanner.scan(
						 (result) => {
							if( result.cancelled || result.text.length === 0 ) return
							setSearchString(result.text)
						},
						function (error) {
								console.warn("Scanning failed or canceled: " + error);
						},
						{ ...defaultBarcodeScannerOptions, 
								preferFrontCamera: this.$store.getters.preferFrontCamera,
								prompt : this.$t('search.scan.barcode'), // Android
						}
				);
			}
		},
		scanQRCode(){
			this.$bvModal.show(this.id4Modal)
			//console.log('opening')
		},
		qrCodefound(decodedString){
			this.$bvModal.hide(this.id4Modal)
			this.setSearchString(decodedString)
		},
	}

}
</script>

<style>
.search-input.form-control-md{
	border-radius: 24px;
}
.search-input {
	min-width: 22em;
}
.search-input-group {
	border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;

}
.search-panel{
	position: relative;
}
.has-search .form-control {
    padding-left: 3rem;
		padding-right: 3rem;
		position: relative;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
		top:0.15em;
		left:0.5em;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.0rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.has-search .form-control-feedback-back {
    position: absolute;
		z-index: 3;
		cursor: pointer;
		top:0.15em;
		right:0.45em;
    /* display: block; */
    width: 1.8rem;
    height: 2.375rem;
    line-height: 2.0rem;
    text-align: center;
    color: #aaa;
}
</style>