<template>
<b-modal size="md" :id="id" 
		header-bg-variant="info"
		header-text-variant="white"
		body-bg-variant="light" 
		
		footer-bg-variant="light"
		:title="title"
		title-class="lead"
		>
			
		<p v-if="subject" class="text-center text-muted smart-size mt-2 mb-4">{{subject}}</p>
		<qrcode-stream @decode="onDecode" @init="onInit" :camera="camera" :torch="torch">
			<div class="scan-command-buttons d-flex justify-content-between">
				
					<b-dropdown id="cameras" text="Switch Camera" >
						<template #button-content>
							<IconFlipCamera />
						</template>
						<b-dropdown-item  v-for="videoDevice in devices" :key="videoDevice.label" @click="setVideoDevice(videoDevice.deviceId)">{{videoDevice.label}}</b-dropdown-item>
					</b-dropdown>
					<b-btn v-if="torchSupported && !torchOn" @click="setTorchOn(true)"><IconFlashlight /></b-btn>
					<b-btn v-if="torchSupported && torchOn" @click="setTorchOn(false)"><IconFlashlightOff /></b-btn>
				
				<!-- <span class="text-warning">	Torch Supported: {{ torchSupported }}, torch: {{torch}} </span> -->

			</div>
			<b-alert class="my-4" v-if="error" show variant="warning">{{error}}</b-alert>
			<div class="scan-helper-area">
				<IconScanHelper class="icon-scan-helper qrcode-scanner-overlay-icon" >
				</IconScanHelper>
			</div>
		</qrcode-stream>
					
	<template #modal-footer="{ hide }">
		<b-button variant="secondary" block @click="hide('forget')">{{$t('close')}}</b-button>
  </template>
				
</b-modal>
</template>

<script>
import  QrcodeStream  from '../../vue-qrcode-reader/components/QrcodeStream.vue'
import { mapGetters, mapState } from 'vuex'
import IconFlipCamera from 'vue-material-design-icons/CameraFlipOutline'
import IconScanHelper from 'vue-material-design-icons/ScanHelper.vue'
import IconQRCodeScan from 'vue-material-design-icons/QrcodeScan.vue'
import IconFlashlight from 'vue-material-design-icons/Flashlight'
import IconFlashlightOff from 'vue-material-design-icons/FlashlightOff'
export default {
	props: ['id', 'onBarcodeDetected', 'subject', 'title'],
	data () {
    return {
//      camera: 'auto',
			torchSupported: false,
			cameraOn: true,
			error: null
			// torchOn: false
    }
  },
	components:{
		QrcodeStream, IconFlipCamera, IconFlashlightOff, IconFlashlight, IconQRCodeScan, IconScanHelper
	},
	computed:{
		...mapGetters(['videoDevices']),
		torchOn: {
			get () {
				return this.$store.getters.videoDeviceTorchOn
      },
      set (value) {
				this.$store.commit('videoDeviceTorchOn', value)
      }
		},
		devices() {
			return this.videoDevices.length?[...this.videoDevices]:[{ label: 'Auto', deviceId: null } ]
		},
		torch(){
			if(!this.torchSupported) return false
			return this.$store.getters.videoDeviceTorchOn
		},
		camera(){ 
			if( !this.cameraOn ) return 'off'
			return this.$store.getters.videoDeviceId?{ deviceId: this.$store.getters.videoDeviceId }:'auto'
		}
	},
	
	methods:{
		setCameraOff(){
			this.cameraOn = false
		},
		setTorchOn(on){
			this.torchOn = on?true:false
		},
		setVideoDevice(deviceId){
			this.cameraOn = true
      this.$store.dispatch('setVideoDeviceId', deviceId)
		},
		async onDecode(decodedString){
			this.onBarcodeDetected(decodedString)
		},
		
		
    async onInit (promise) {
      try {
				const { capabilities } = await promise
				console.log('capatibilities:', capabilities)				
				this.torchSupported = !!capabilities.torch

				// const supportedConstraints =  navigator.mediaDevices.getSupportedConstraints?navigator.mediaDevices.getSupportedConstraints():capabilities
				// console.log('supportedConstraints:', supportedConstraints)				
				// this.torchSupported = !!supportedConstraints.torch
				
				this.error = null
				
				this.$store.dispatch('setVideoDeviceId', capabilities.deviceId)
				this.$store.dispatch('setupVideoDevices')
      } catch (error) {
				if (error.name === 'NotAllowedError') {
					// user denied camera access permisson
					this.error = this.$t('qrcode.camera.error.NotAllowedError')
				} else if (error.name === 'NotFoundError') {
					// no suitable camera device installed
					this.error = this.$t('qrcode.camera.error.NotFoundError')
				} else if (error.name === 'NotSupportedError') {
					// page is not served over HTTPS (or localhost)
				} else if (error.name === 'NotReadableError') {
					this.error = this.$t('qrcode.camera.error.NotReadableError')
					// maybe camera is already in use
				} else if (error.name === 'OverconstrainedError') {
					this.error = this.$t('qrcode.camera.error.OverconstrainedError')
					// did you requested the front camera although there is none?
				} else if (error.name === 'StreamApiNotSupportedError') {
					// browser seems to be lacking features
					this.error = this.$t('qrcode.camera.error.StreamApiNotSupportedError')
				}
        
      }
		}
	}
}

</script>

<style scoped>
.scan-command-buttons{
	position: absolute;
	width: 100%;
	z-index: 10;
}
.error {
  color: red;
  font-weight: bold;
}
.material-design-icon.icon-scan-helper {
  height: 68%;
  width: 68%;
}
.scan-helper-area{
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 0.68;
	
	text-align: center;
		
}
.material-design-icon.icon-scan-helper > .material-design-icon__svg {
  height: 68%;
  width: 68%;
}
.qrcode-scanner-overlay-icon{
	color: cornsilk;
	position: relative; 
	top: 15%;
}
</style>