<template>
	<b-modal size="lg" id="modal-anmeldedaten" 
		header-bg-variant="info"
		header-text-variant="white"
		body-bg-variant="light" 
		
		footer-bg-variant="light"
		:title="$t('modal.anmeldedaten.title')">
		
		<p class="text-center lead my-4">{{$t('modal.anmeldedaten.lead.message')}}</p>
		<h4 class="text-center my-4">{{lcRecord.title}}</h4>
		<b-form v-on:submit.prevent="">
			<b-row class="form-group">
				<b-col lg class="text-sm-right text-left">
					<b-form-group
						id="nlcd-username" label-class="font-weight-bold"
						label-cols-sm="4" label-cols-lg="3" 
						:label="$t('setup.login.label.backend.url')"
						
						label-for="url">
						
						<b-form-input id="url"  readonly
							:value="backendURL" type="text"   />
					
					</b-form-group>
					<b-form-group
						id="nlcd-username" label-class="font-weight-bold"
						label-cols-sm="4" label-cols-lg="3" 
						:label="$t('setup.login.label.login')"
						
						label-for="loginname">
						
						<b-form-input id="loginname"  readonly
							:value="lcRecord.username" type="text"   />
					
					</b-form-group>
					<b-form-group
						id="nlcd-username" label-class="font-weight-bold"
						label-cols-sm="4" label-cols-lg="3" 
						:label="$t('setup.login.label.password')"
						:description="$t('anmeldedaten.tipp')"
						label-for="passwd">
						
						<b-form-input  id="passwd"  readonly
							:value="lcRecord.password" type="text" />
					
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>
		<div class="text-center " >
			<h5>{{$t('anmeldebarcode')}}</h5>
			<div class="mx-5 p-4 bg-white">
				<p class="small text-center">{{$t('anmeldedaten.barcode.description')}}</p>
       	<qrcode-vue id="tooltip-target-barcode" class="d-inline-block"  :value="contentOfQRCode"></qrcode-vue>
							<b-tooltip placement="bottom" variant="primary" target="tooltip-target-barcode" triggers="hover">
							{{$t('anmeldedaten.barcode.tooltip')}}
						</b-tooltip>
				</div>
		</div>
	<template #modal-footer="{ hide }">
		<b-button variant="info" @click="copyToClipboard()" >{{$t('copy.to.clipboard')}}</b-button>
		<b-button variant="secondary" @click="hide('forget')">{{$t('close')}}</b-button>
  </template>
	</b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import QrcodeVue from 'qrcode.vue'
export default {
	components: { QrcodeVue },
	computed:{
		 ...mapGetters(['lcRecords', 'backendURL', 'modalAnmeldedatenLcId']),
		 libertas_center_id() { return this.modalAnmeldedatenLcId },
		 lcRecord(){ 
			 const lcRecord = this.lcRecords.find( w => w.libertas_center_id === this.libertas_center_id)
			 return lcRecord?lcRecord:{} },
		 contentOfQRCode(){
			 return JSON.stringify({ bctp: 'lcSetup', backend: this.backendURL, login: this.lcRecord.username, pwd: this.lcRecord.password })
			 //{"login":"lcOne","pwd":"secret","bctp":"lcSetup","backend":"https://wetnotes.t-provider.de/libertas/backend"}
		 },
		 text4Clipboard(){
			const theText = 
`Libertas Professionals. Zugangsdaten für: ${this.lcRecord.title}

Server URL: 	${this.backendURL}
Benutzername: ${this.lcRecord.username}
Kennwort: ${this.lcRecord.password}`
			return theText
		 }
	},
	methods:{
		toastCopyToClipboardOk(){
			this.$bvToast.toast(this.$t('copied.to.clipboard.text'), {
				title: this.$t('copied.to.clipboard.title'),
				autoHideDelay: 3600,
				variant:'info'
			})
		},
		toastCopyToClipboardNok(){
			this.$bvToast.toast('Beim Kopieren in die Zwischenablage ist ein Fehler aufgetreten', {
				title: 'Ups, Hat nicht funktioniert',
				autoHideDelay: 3600,
				variant:'danger'
			})
		},
		copyToClipboard(){ 
			const theText = this.text4Clipboard
			if( this.$store.getters.isMobileAppMode && 'browser' !== window.cordova.platformId){
				window.cordova.plugins.clipboard.copy(theText, this.toastCopyToClipboardOk, this.toastCopyToClipboardNok);
			}else{
				this.clipboard(theText) 
				this.toastCopyToClipboardOk()
			}
		},

		clipboard (str) {
      const el = document.createElement('textarea');
      el.addEventListener('focusin', e => e.stopPropagation());
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
	}
}
</script>

<style>

</style>