<template>
	<b-nav-item :active="isActive"  @click="setUpView()" >
    <element-view-icon />
		{{$t('lcrecords.view.confirmed')}} <b-badge variant="light">{{countOfItems}}</b-badge>
  </b-nav-item>
</template>

<script>
import ElementViewIcon from 'vue-material-design-icons/CheckDecagram.vue'
import { viewFilterSet } from '../../../store/administrationUtil'
import { mapGetters } from 'vuex'
export default {
	components: { ElementViewIcon },
	computed:{
		...mapGetters(['lcRecordsOfConfirmed']),
		isActive(){ return this.$store.getters.isViewConfirmed },
		countOfItems() { return this.lcRecordsOfConfirmed.length }
	},
	methods:{
		setUpView(){
			this.$store.dispatch('setDisplayFilterStack', viewFilterSet.confirmed)
		}
	}
}
</script>

<style>
</style>