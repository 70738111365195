<template>
	<div>
		<h5>{{$t('overview.dashboard.header')}}</h5>
		
		<ReceiptsEmissionTotal class="mt-4"/>
		<div>
		Insgesamt Belege : {{ overviewKeyFigures.receipt_count_total }} davon heute : {{ overviewKeyFigures.receipt_count_today }} 
		</div>
		
	<div class="my-2"><b-btn @click="details()" variant="info">Details</b-btn></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReceiptsEmissionTotal from './ReceiptsEmissionTotal'
export default {
	components: { ReceiptsEmissionTotal },
	computed:{
		...mapGetters([ 'overviewKeyFigures' ]),
		countReceiptTypes() { return this.$store.getters.kkReceiptTypes.length },
	},
	methods:{
		details(){
      	this.$bvToast.toast('Kommt demnächst .... In Entwicklung', {
						title: 'Detaillierte Informationen',
						autoHideDelay: 12000,
						variant:'info'
						})
		},
		
	},

}
</script>

<style>

</style>