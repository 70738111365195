<template>
<div>
			
	<h5 class="my-4">§ 1 Geltungsbereich/ Änderungsvorbehalt</h5>
	<p >
		Die nachfolgenden Allgemeinen Nutzungsbedingungen gelten für die Nutzung der Applikation "Libertas Center Applikation"
		betrieben von den MyEpa UG (nachfolgend "Dienstanbieter"). 
	</p>
	<p>
		Der Dienstanbieter behält sich vor, die Nutzungsbedingungen jederzeit zu ändern und die weitere Nutzung der 
		Applikationen von der Zustimmung zu den geänderten Nutzungsbedingungen abhängig zu machen.
	</p>
	<h5 class="my-4">§ 2 Bereitstellung der Applikationen und Funktionalitäten</h5>
	<p>
		Der Dienstanbieter behält sich vor, sofern es nicht anders verträglich geregelt, die Applikation und Funktionen ganz oder teilweise jederzeit zu ändern, 
		zu beenden oder den Betrieb der gesamten Butler Projektes jederzeit ganz einzustellen. 
		Der Dienstanbieter gewährleistet keine ununterbrochene Verfügbarkeit der Dienste wird jedoch im Falle von Verfügbarkeitsproblemen 
		darauf hinwirken, in seinem Verantwortungsbereich liegende Störungen unverzüglich zu beheben.
	</p>
	<h5 class="my-4">§ 2 Beschreibung der Dienstleistung</h5>
	<p>Die Libertas Center Applikation in ihrer Kernfunktionalität dient der Ausstellung der nachprüfbaren Betsätigunenen (wie z.B. Testergebnisse, und anderen Nachweise) an die Nutzer der Libertas App.
	</p>
	<h5 class="my-4">§ 3 Datenhaltung und Datennutzung</h5>
	<h6 class="my-4">§ 3.1 Datenhaltung für Kernfunktionalität</h6>
	<p>
		Die Libertas Center Applikation benötigt für ihre Kernfunktionalität ein Netzwerkzugriff zum einem Libertas Server. 
		Für die Authorizierung beim Server erfolgt eine Anmeldung der Applikation beim Server.
	</p>
	<h6 class="my-4">§ 3.2 Datenhaltung für zusätzliche Angaben</h6>
	<p>
		... on going
	</p>
	<h6 class="my-4">§ 3.3 Datenhaltung auf dem Butler Backend Server</h6>
	<p>...
	</p>
	<h5 class="my-4">§ 4 Haftungsbeschränkung</h5>
	<p >
		Trotz sorgfälltiger Planung, Entwicklung und Testung der Software sind die Softwarefehler nicht ausgeschlossen. Der Dienstanbieter nach bestem Wissen und Gewissen stets daran intereesiert diese Fehler und vermeiden und im ggf. schnellstmöglich zu beseitigen.
		Jedoch müssen einige Abgrenzungen klar festgelegt werden.</p>
		<p>
		Der Dienstanbieter übernimmt keine Haftung für eventuelle Personen- oder Sach- Schäden oder Gewinnausfälle, welche durch direkte oder indirekte Nutzung des Angebotes entstehen können.
		Der Dienstanbieter haftet NICHT für eine eventuelle "nicht Zustellung des Aufrufes" und dessen Folgen, welche durch technische Gegebenheiten verursacht werden.
		Der Dienstanbieter übernimmt keine Haftung für Hardware und Infrastruktur, auf der die Software- Applikation betrieben wird.
	</p>

</div>
</template>

<script>
export default {

}
</script>

<style>

</style>