<template>
	<div v-bind:class="{ 'with-header-offset': withHeaderOffset}" >
		<LcRecordsPanel />
	<b-container class=" mx-lg-5 mt-2" fluid="xxl">
			<feedback-message />
			
	<h4 class="smart-size">{{$t('register.libertas.center')}}</h4>
		<!-- <WaitersBoard class="pt-4" v-if="showBoard" /> -->
		<LcRecordsList class="mt-3" v-if="showList"/>
		<AddLcRecord />
		<EmptyTrash v-if="isShowEmptyTrash" />
		<modal-anmelde-daten />
	</b-container>		
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FeedbackMessage from '../../components/FeedbackMessage'
import LcRecordsPanel from './LcRecordsPanel'
import moment from 'moment'
import LcRecordsList from './LcRecordsList'
import AddLcRecord from './AddLcRecord'
import ModalAnmeldeDaten from './lcRecord/ModalAnmeldeDaten.vue'


//import ModalEditPublicKey from './ModalEditPublicKey.vue'
export default {
	computed:{
		...mapGetters(['isAdminAccount', 'lcRecordsViewMode' ]),
		withHeaderOffset() { return this.$store.getters.isHeaderFixedTop },
		showBoard(){ return this.lcRecordsViewMode === 'board'},
		showList(){ return this.lcRecordsViewMode === 'list'},
		isShowEmptyTrash(){ return this.$store.getters.isViewPapierkorb && this.$store.getters.lcRecordsOfPapierkorb.length > 0 }
	},
	components:{ FeedbackMessage, 
	 LcRecordsPanel, LcRecordsList, AddLcRecord, ModalAnmeldeDaten
	// EmptyTrash, WarteraumPanel, AddWaiter, WaitersBoard, BarcodeModal,
		},
	created() {
		this.handleIntervalDisplayMoment = setInterval(() => { this.$store.commit('displayMoment', moment()) }, 1000)
		this.handleIntervalFetchServerData = setInterval(() => { 
			this.$store.dispatch('updateLcRecords') 
		}, 60 * 1000)
	},
	async mounted(){
		this.$store.dispatch('updateLcRecords')
	},
	activated(){
		
	},
	beforeDestroy() {
		clearInterval(this.handleIntervalDisplayMoment)
		clearInterval(this.handleIntervalFetchServerData)
	},	
	methods:{

	}

}
</script>

<style scoped>

/* Small devices (landscape phones, 544px and up) */
@media (max-width: 543px) {  
	.with-header-offset{ padding-top: 60px; }	
}
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  .with-header-offset{ padding-top: 62px; }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .with-header-offset{ padding-top: 63px; }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .with-header-offset{ padding-top: 63px; }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .with-header-offset{ padding-top: 65px; }
}

</style>


		