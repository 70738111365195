import Either from 'data.either'
import backend from '../backend'
import i18next from '../i18n/i18n'
import moment from 'moment'
import { nanoid } from 'nanoid'
import uuid from 'uuid'
import { debounce } from 'debounce'
import { fetchJson, fetchJsonWithBasicAuth, fetchJsonWithJWT, catchResponse401ResetAuthToken, parseJwt } from '../commons/utils'
import { filterLcRecords, isViewActive, viewFilterSet } from './administrationUtil'
import lcRecordUtil from '../admin/lcRecords/lcRecordUtil'
import { getDefaultLcRecord } from './lcState'
import * as validatoren from '../components/lcRecords/lcRecord/validatoren'
/**
 * TRANSIENT, wird nicht gespeichert
 */
const modulState = {
	state: () => ({
		libertas_center_id: null,
		password: null,

		username: null,
		usernameInvalidFeedback: '',
		usernameValidationState: null,

		title: null,
		titleInvalidFeedback: '',
		titleValidationState: null,

		plz: null,
		plzInvalidFeedback: '',
		plzValidationState: null,

		contact: null,
		contactInvalidFeedback: '',
		contactValidationState: null,

		description: null,
		descriptionInvalidFeedback: '',
		descriptionValidationState: null,

		admin_account: 0,
		confirmed: null
		
	}),
	getters:{
		nlcdPassword: state => state.password,

		nlcdUsername: state => state.username,
		nlcdUsernameInvalidFeedback: state => state.usernameInvalidFeedback,
		nlcdUsernameValidationState: state => state.usernameValidationState,

		nlcdTitle: state => state.title,
		nlcdTitleInvalidFeedback: state => state.titleInvalidFeedback,
		nlcdTitleValidationState: state => state.titleValidationState,

		nlcdPlz: state => state.plz,
		nlcdPlzInvalidFeedback: state => state.plzInvalidFeedback,
		nlcdPlzValidationState: state => state.plzValidationState,

		nlcdContact: state => state.contact,
		nlcdContactInvalidFeedback: state => state.contactInvalidFeedback,
		nlcdContactValidationState: state => state.contactValidationState,

		nlcdDescription: state  => state.description,
		nlcdDescriptionInvalidFeedback: state  => state.descriptionInvalidFeedback,
		nlcdDescriptionValidationState: state  => state.descriptionValidationState,

		nlcdAdminAccount: state => state.admin_account,
		nlcdConfirmed: state => state.confirmed,

		nlcdCreateEnabled: state => {
			return state.usernameValidationState === true  &&
			 state.titleValidationState === null && state.title &&
			 state.contactValidationState === null &&
			 state.descriptionValidationState === null &&
			 state.plzValidationState === null 
		}

//		showWarningMaxAllowedLcRecords: (state, getters, rootState) => getters.lcRecords.length > state.maxAllowedLcRecords,
	},
	mutations: {
		nlcdUsername: (state, payload) => { state.username = payload },
		nlcdUsernameInvalidFeedback: (state, payload) => { state.usernameInvalidFeedback = payload },
		nlcdUsernameValidationState: (state, payload) => { state.usernameValidationState = payload },
		
		nlcdTitle: (state, payload) => { state.title = payload },
		nlcdTitleInvalidFeedback: (state, payload) => { state.titleInvalidFeedback = payload },
		nlcdTitleValidationState: (state, payload) => { state.titleValidationState = payload },
		
		nlcdPlz: (state, payload) => { state.plz = payload },
		nlcdPlzInvalidFeedback: (state, payload) => { state.plzInvalidFeedback = payload },
		nlcdPlzValidationState: (state, payload) => { state.plzValidationState = payload },
		
		nlcdContact: (state, payload) => { state.contact = payload },
		nlcdContactInvalidFeedback: (state, payload) => { state.contactInvalidFeedback = payload },
		nlcdContactValidationState: (state, payload) => { state.contactValidationState = payload },

		nlcdDescription: (state, payload) => { state.description = payload },
		nlcdDescriptionInvalidFeedback: (state, payload) => { state.descriptionInvalidFeedback = payload },
		nlcdDescriptionValidationState: (state, payload) => { state.descriptionValidationState = payload },

		nlcdConfirmed: (state, payload) => { state.confirmed = payload },
		nlcdInitFields: (state, lcRecord) => { 
			state.libertas_center_id = lcRecord.libertas_center_id

			state.username = lcRecord.username
			state.usernameValidationState = null
			state.usernameInvalidFeedback = ''
			
			state.title = ''
			state.titleValidationState = null
			state.titleInvalidFeedback = ''
			
			state.plz = ''
			state.plzValidationState = null
			state.plzInvalidFeedback = ''
			
			state.contact = ''
			state.contactValidationState = null
			state.contactInvalidFeedback = ''
			
			state.description = ''
			state.descriptionValidationState = null
			state.descriptionInvalidFeedback = ''
			state.password = lcRecord.password
			
			state.admin_account = 0
			state.confirmed = lcRecord.confirmed
		},
		nlcdAdminAccount: (state, payload) => { state.admin_account = payload}
  },
	actions: {
		test: (ctx) => console.log('newLCRecordState - action'),
		nlcdAdminAccount: (ctx, payload) => {
			ctx.commit('nlcdAdminAccount', payload?1:0)
		},
		nlcdConfirmed: (ctx, payload) => {
			ctx.commit('nlcdConfirmed', payload?moment().toISOString():null)
		},

		nlcdUsername: (ctx, payload) => {
			ctx.commit('nlcdUsername', payload)
			const validator = validatoren.usernameInvalidator
			const error = validator(ctx.getters.nlcdUsername)
			ctx.commit('nlcdUsernameInvalidFeedback', error?error:'')
			ctx.commit('nlcdUsernameValidationState', error?false:null)

			ctx.dispatch('nlcdEmsureUsernameIsAvailable')
			
		},
		nlcdEmsureUsernameIsAvailable: (ctx) => {
			// debouncedValidation
			debouncedUsernameValidation(ctx)
		},
		nlcdCreateAccount: async (ctx) => {
			const result = await createAccountOnBackend(ctx)
			catchResponse401ResetAuthToken(ctx, result)
			if(result.isRight) ctx.dispatch('updateLcRecords')
			return result
		},

		nlcdTitle: (ctx, payload) => {
			ctx.commit('nlcdTitle', payload)
			const validator = validatoren.requiredFieldMin3Max256Invalidator
			const error = validator(ctx.state.title)
			ctx.commit('nlcdTitleInvalidFeedback', error?error:'')
			ctx.commit('nlcdTitleValidationState', error?false:null)
						
		},
		nlcdPlz: (ctx, payload) => {
			ctx.commit('nlcdPlz', payload)
			const validator = validatoren.plzInvalidator
			const error = validator(ctx.state.plz)
			ctx.commit('nlcdPlzInvalidFeedback', error?error:'')
			ctx.commit('nlcdPlzValidationState', error?false:null)
		},
		nlcdContact: (ctx, payload) => {
			ctx.commit('nlcdContact', payload)
			const validator = validatoren.min3maxLength256Invalidator
			const error = validator(ctx.state.contact)
			ctx.commit('nlcdContactInvalidFeedback', error?error:'')
			ctx.commit('nlcdContactValidationState', error?false:null)
		},
		nlcdDescription: (ctx, payload) => {
			ctx.commit('nlcdDescription', payload)
			const validator = validatoren.maxLength1024Invalidator
			const error = validator(ctx.state.description)
			ctx.commit('nlcdDescriptionInvalidFeedback', error?error:'')
			ctx.commit('nlcdDescriptionValidationState', error?false:null)
		},
		initDialogNewLibertasCenter: async(ctx) => {
			const defaultLcRecord = getDefaultLcRecord()
			defaultLcRecord.password = nanoid().substring(0, 9)
			defaultLcRecord.confirmed = moment().toISOString()
			ctx.commit('nlcdInitFields', defaultLcRecord)
			await debouncedUsernameValidation(ctx)
		}
	}
}
export default modulState

const extractFromCtx = (ctx) => {
	return { backendURL : ctx.rootGetters.backendURL, jwt : ctx.rootGetters.kkJWT }
}

const createAccountOnBackend = async (ctx) => {
	const libertas_center_id = ctx.state.libertas_center_id
	const { backendURL, jwt } = extractFromCtx(ctx)
	const { username, title, description, contact, plz, admin_account, confirmed, password} = ctx.state

  const allowed_receipt_types = ctx.rootGetters.defaultAllowedReceiptTypes
	const profile = { allowed_receipt_types }
	
	const payload = { username, title, 
		description:description?description:null, 
		contact:contact?contact:null, 
		plz:plz?plz:null, 
		admin_account, confirmed, password, profile}
	const url = `${backendURL}${backend.URL_POST_PATCH_DELETE_LC_RECORD}/${encodeURIComponent(libertas_center_id)}`

	const result = await fetchJsonWithJWT(jwt, url, 'POST', payload)
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			payload.libertas_center_id = libertas_center_id
			ctx.commit('mergeLcRecord', payload) // die Zeile wird überschrieben, jedoch ohne password, deswegen hier schon mal speichern
			return Either.Right(json)
		}
	}
	return result
}

const ensureUsernameIsFree = async function(ctx){
	const username = ctx.state.username
	if(!username || !username.trim()) return
	const libertas_center_id = ctx.state.libertas_center_id
	const { backendURL, jwt } = extractFromCtx(ctx)

	const url = `${backendURL}${backend.URL_GET_LC_BY_USERNAME}/${encodeURIComponent(username)}`
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			let error = null
			if( json[0] && libertas_center_id !== json[0].libertas_center_id ){
				error = i18next.t( 'username.already.in.use' )
			} 
			this.textInvalidFeedback = error?error:''
			this.stateValidation = error?false:true
			ctx.commit('nlcdUsernameInvalidFeedback', error?error:'')
			ctx.commit('nlcdUsernameValidationState', error?false:true)
			
		}
	}
	catchResponse401ResetAuthToken(ctx, result)
}
export const debouncedUsernameValidation = debounce(ensureUsernameIsFree, 1024)










