<template>
<div>
	<p class="small text-left">{{$t('persist.server.authorisation.description')}}</p>
	
	<b-form-group class="text-left"
		label-align="left"
		label-align-sm="right"
		id="persistServerAuthorisationFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('persist.server.authorisation.label')"
		label-for="input-persistsserverauthorisation"
	>
	<b-form-checkbox switch class="mx-2 py-2" v-model="persistServerAuthorisation">
		<span class="sr-only">persist server authorisation</span>
	</b-form-checkbox> 
		
	</b-form-group>

	<b-input-group>
		<b-form-text></b-form-text>
	</b-input-group>
</div>
</template>

<script>
export default {
		computed:{
			persistServerAuthorisation: {
			 get () {
        return this.$store.getters.persistServerAuthorisation
      },
      set (value) {
				this.$store.dispatch('persistServerAuthorisation', value)
      }
		}
		
	},

}
</script>

<style>

</style>