<template>
<div v-bind:class="{'d-inline-block m05 ' : !viewValue && !isEditMode}">
	<div  :title="title" v-if="!isEditMode" @click="toEditMode()">

		<div v-if="!viewValue">
			<IconAttribute  :icon="icon"/>
		</div>
		<div v-else :class="viewClass">
			<b-icon v-if="!hideIconOnValue" :icon="icon" />&nbsp;
			{{viewTextValue}} 
		</div>
	</div>
	
	<div v-if="isEditMode" class="my-1">
		<div class="form-group required row">
			<div class="col-sm-12">
				<!-- @keyUp="onUserPasswortKeyUp($event)" -->
				<b-form @submit.stop.prevent>
						<b-form-textarea v-if="isTextArea"
    				  ref="editVaueInput"
      				v-model="editValue" :state="stateValidation"
      				:placeholder="placeholder" 
							@keyup="editOnKeyUp($event)" @blur="updateValue()" aria-describedby="input-help-block"
      				rows="5" max-rows="8">
						</b-form-textarea>
      			<b-input v-else ref="editVaueInput" :type="getInputType" :placeholder="placeholder" :state="stateValidation"
							@keyup="editOnKeyUp($event)" @blur="updateValue()" aria-describedby="input-help-block input-live-feedback" v-model="editValue"></b-input>
						<b-form-invalid-feedback id="input-live-feedback">{{ textInvalidFeedback }}</b-form-invalid-feedback>
						<b-form-text id="input-help-block">{{placeholder}}</b-form-text>
				</b-form>
			</div>
		</div>
	</div>
</div> 		

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import  IconAttribute  from './IconAttribute'

export default {
	data: function () {
		return {
			isEditMode: false,
			editValue: this.viewValue?this.viewValue:'',
			stateValidation: null,
			textInvalidFeedback: '',
			}
	},
	components: { IconAttribute },
	props: ['libertas_center_id', 'attributeName', 'icon', 'hideIconOnValue', 'viewClass', 
	'inputType', 'viewTextMaxLength',
	'validator', 'debouncedValidator'],
	computed:{
		 ...mapGetters(['lcRecords']),
		 isTextArea(){ return 'textarea' === this.inputType },
		 getInputType(){ return this.inputType?this.inputType:'text' },
		 lcRecord(){ return this.lcRecords.find( w => w.libertas_center_id === this.libertas_center_id) },
		 viewValue(){ return this.lcRecord?this.lcRecord[this.attributeName]:'' },
		 //isValid(){ return !this.editValue?null:check(this.editValue).isLength(3, 8) },
		 viewTextValue(){ 
			 const shortedText = this.viewTextMaxLength?this.lcRecord[this.attributeName].substring(0, this.viewTextMaxLength):this.viewValue
			 return shortedText.length < this.viewValue.length?shortedText+'...':shortedText },

		 title(){ return this.$t(`lcRecord.${this.attributeName}.title`)+ (this.lcRecord[this.attributeName]?": "+ this.lcRecord[this.attributeName]:"") },
		 placeholder(){ return this.$t(`lcRecord.${this.attributeName}.placeholder`) }
	},
	methods:{

		verifyUsernameIsFree(username) { 
			debouncedVerifyUserNameIsFree(this.libertas_center_id, username) 
		},

		validateValue(str){
				if( this.validator ) {
					const error = this.validator(str)
					this.textInvalidFeedback = error?error:''
					this.stateValidation = error?false:null
				}

				if(this.debouncedValidator && this.stateValidation === null ){
					this.debouncedValidator( str )
				}
		},

		toEditMode(){
			this.editValue = this.viewValue
			this.isEditMode = true
			this.validateValue(this.editValue)
			// this.stateValidation = null
			// this.textInvalidFeedback = ''
			setTimeout(() => {
			if(this.$refs.editVaueInput) {
				if( !this.isTextArea ) this.$refs.editVaueInput.select()
					this.$refs.editVaueInput.focus()
				}
			}, 128)
		},
		editOnKeyUp(event){
			this.validateValue(this.editValue)
			if( event.keyCode === 13 && (!this.isTextArea || event.ctrlKey)) {
			//if( event.keyCode === 13 ) {
				console.log(event)
				this.updateValue()
			}
			if( event.keyCode === 27 ) this.isEditMode = false
			
		},
		async updateValue(){
			if(!this.isEditMode) return
			if( this.stateValidation === false){
				this.isEditMode = false
				return
			} 
			let newValue = this.editValue?this.editValue.trim():null
			
			this.isEditMode = false
			const patch = {}
			patch[this.attributeName] = newValue
			const updatePayload = { libertas_center_id : this.libertas_center_id , patch }
			const result = await this.$store.dispatch('patchAttributeOfLcRecord', updatePayload)
			if(result.isRight){
				/*
				const lcRecord = { libertas_center_id: this.libertas_center_id }
				lcRecord[this.attributeName] = newValue
				this.$store.commit('mergeLcRecord', lcRecord)
				*/
				console.log('patch sent intent update call to backend')
				const resultPullUpdate = this.$store.dispatch('updateLcRecords') 
				if(resultPullUpdate.isLeft){
						this.$bvToast.toast(this.$t('common.network.issue.text'), {
          			title: this.$t('common.network.issue.title'),
								autoHideDelay: 3200,
								variant:'warning'
								})
				}

			}else{
				this.$bvToast.toast(this.$t('lcrecord.patch.failed'), {
          			title: this.$t('lcrecord.patch.failed.title'),
								autoHideDelay: 3200,
								variant:'warning'
				})
			}

			
		}
	}

}
					
</script>

<style>

</style>