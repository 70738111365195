import Either from 'data.either/lib/either'
import { fetchJsonWithBasicAuth } from './commons/utils'

export const URL_GET_TR_4_1 = `/referenzdaten/tr_4_1`
export const URL_GET_POST_PUBLIC_KEYS = `/aek/base/public_key`

// Libertas URLS
export const URL_GET_LC_RECORDS = `/administration/libertascenters`
export const URL_GET_LC_BY_USERNAME = `/administration/libertasCenter/by-username`
export const URL_POST_PATCH_DELETE_LC_RECORD = `/administration/libertasCenter`
export const URL_GET_RECEIPT_TYPE = `/public/references/receipt_type`
export const URL_GET_DATA_LIBERTAS_CENTER = `/lc/main/data-libertas-center`
export const URL_GET_OVERVIEW_KEY_FIGURES = `/administration/overview/keyfigures`

export const URL_GET_OVERVIEW_RECEIPTS_EMISSION = `administration/overwiew/receipts_emission`
export const URL_GET_SUMMARY_RECEIPTS_EMISSION = `administration/summary/receipts_emission`

export const URL_POST_CREDENTIAL_CREATION_OPTIONS = `/libertasCenter/webauthn/credentials/optionsRegisterNewToken`
export const URL_POST_REGISTER_CREATED_CREDENTIAL = `/libertasCenter/webauthn/credentials/resultNewTokenCredentialCreation`
export const URL_POST_CREDENTIAL_NICKNAME = `/libertasCenter/webauthn/credentials/credentialNickname`
export const URL_DELETE_CREDENTIAL = `/libertasCenter/webauthn/credentials/credential`

export const URL_POST_CHANGE_PASSWORD = `/lc/main/changePassword`
export const URL_GET_CREDENTIALS = `/libertasCenter/webauthn/credentials/credentials`
export const URL_LOGIN_WEBAUTHN_OPTIONS = `/libertasCenter/webauthn/assert/options`
export const URL_LOGIN_WEBAUTHN_ASSERTION_RESULT = `/libertasCenter/webauthn/assert/resultAssertion`
export const URL_GET_AUTH_TOKEN = `/lc/login/authJWT`
export const URL_JWT_PUBLIC_KEY = `/public/jwtPublicKey`

export const UNEXPECTED_STATUS ='unexpected_status'

const getAuthJWT = async (backendURL, login, password) => {
	const result = await fetchJsonWithBasicAuth(login, password, `${backendURL}${URL_GET_AUTH_TOKEN}`, 'GET')
	//console.log(result)
	if(result.isRight){
		if(result.value.response.status === 200){
			return Either.Right({ ...result.value.json })
		}
	} 
	return result
}

export default { getAuthJWT, 
	URL_GET_AUTH_TOKEN, URL_POST_CREDENTIAL_CREATION_OPTIONS, URL_POST_REGISTER_CREATED_CREDENTIAL,
	URL_DELETE_CREDENTIAL, URL_POST_CHANGE_PASSWORD, URL_POST_CREDENTIAL_NICKNAME, URL_LOGIN_WEBAUTHN_OPTIONS,
	URL_GET_CREDENTIALS, URL_LOGIN_WEBAUTHN_ASSERTION_RESULT, URL_GET_RECEIPT_TYPE, URL_GET_POST_PUBLIC_KEYS, URL_GET_TR_4_1,
	URL_GET_DATA_LIBERTAS_CENTER, URL_GET_OVERVIEW_KEY_FIGURES, URL_GET_LC_BY_USERNAME,
	URL_JWT_PUBLIC_KEY, URL_GET_LC_RECORDS, URL_POST_PATCH_DELETE_LC_RECORD, URL_GET_SUMMARY_RECEIPTS_EMISSION, URL_GET_OVERVIEW_RECEIPTS_EMISSION
}
