<template>
	<b-alert  show v-if="showWarningMaxAllowedLcRecordsInView" variant="warning">
		{{$t('warning.max.allowed.waiters.in.view')}}
	</b-alert>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters(['showWarningMaxAllowedLcRecordsInView'])
	}

}
</script>

<style>

</style>