import displayFilterRegitry from '../admin/lcRecords/displayFilter'

export const isFilterStackEqual = (array1, array2) => {
	const array2Sorted = array2.slice().sort()
	const array1Sorted = array1.slice().sort()
	if(array1Sorted.length !== array2Sorted.length) return false
	const equal = array1Sorted.every( (value, index) => value === array2Sorted[index] )
	//console.log('equal', equal)
	return equal
}

export const isViewActive = (displayFilterStack, filterSet) => {
	const cleanedSet = JSON.parse(JSON.stringify(displayFilterStack));
	return isFilterStackEqual(cleanedSet, filterSet) 
}

export const filterLcRecords = ( filterNames, rootState ) => {
	
	const allLcRecords = rootState.lcState.lcRecords
	const filterStackReducer = ( lcRecords, displayFilterName ) => lcRecords.filter(displayFilterRegitry[displayFilterName])

	const filterNamesSet = []
	// system filter
	if(rootState.lcState.filterOrphaned) filterNamesSet.push(rootState.lcState.filterOrphaned)
	// user view
	filterNamesSet.push(...filterNames)
	
	return filterNamesSet.reduce(filterStackReducer, allLcRecords)

}

export const viewFilterSet = {
	mainView: [ 'filterDeleted', 'filterDimissed' ],
	confirmed: [ 'filterDeleted', 'filterConfirmedOnly' ],
	toBeConfirmed: [ 'filterDeleted', 'filterConfirmed' ],
	// dimissed: [ 'filterDeleted', 'filterDimissedOnly' ],
	papierkorb: [ 'filterDeletedOnly' ]
}
