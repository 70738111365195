<template>
	<b-container fluid
		class="app-panel bg-white "
			v-bind:style="{'margin-top' : marginTop + 'px' }"
	>	
		<b-table-lite id="lcrecords_list" striped :no-local-sorting="true"
		class="text-muted text-left" head-variant="light" :empty-html="$t('liste.empty.leer')" show-empty
		small :responsive="true" 
			:items="lcRecordsToDisplay"
			thead-tr-class="smart-size text-default "
			:fields="fields"
		>
   		<template #cell(pinned)="row" class="align-middle">
      	<PinControl :libertas_center_id="row.item.libertas_center_id" /> <IsHot :libertas_center_id="row.item.libertas_center_id" view-class="text-primary" krk="12" />
      </template>
   		<template #cell(username)="row" >
				 <LcRecordAttribute :libertas_center_id="row.item.libertas_center_id" viewTextMaxLength="32" 
				 :validator="validateUsername" :debouncedValidator="verifyUsernameIsFree"
					hideIconOnValue="1" attributeName="username" icon="shield-check" 
					view-class="smart-size font-weight-bold clickableValue" />
      </template>

   		<template #cell(title)="row" >
				<LcRecordAttribute :libertas_center_id="row.item.libertas_center_id"
					attributeName="title" :validator="requiredFieldMaxLength256"
					hideIconOnValue="1"  icon="pencil-square" 
					v-bind:viewClass="row.item.admin_account === 1?'font-weight-bold smart-size clickableValue':'smart-size clickableValue'"  />
      </template>

   		<template #cell(plz)="row" class="smart-size">
				<LcRecordAttribute :libertas_center_id="row.item.libertas_center_id"
				  :validator="checkPLZ" 
					hideIconOnValue="1" attributeName="plz" icon="geo" view-class="smart-size clickableValue" />
      </template>

   		<template #cell(contact)="row" >
				<LcRecordAttribute  :libertas_center_id="row.item.libertas_center_id" inputType="text"
				  :validator="checkContact"
					hideIconOnValue="1" attributeName="contact" icon="telephone-outbound" view-class="smart-size clickableValue" viewTextMaxLength="16" />
      </template>
 
   		<template #cell(created)="row" >
				<Erstellt :libertas_center_id="row.item.libertas_center_id" class="smart-size"/>
      </template>

			<template #cell(actions)="row"  >
        <span @click="row.toggleDetails">
            <IconLess v-if="row.detailsShowing" class="text-secondary" /><IconMore class="text-secondary" v-else />  
					
        </span>
      </template>

			<template #row-details="row" class="text-center"  >
			
				<b-row class="bg-light" align-h="end" >
					<b-col class="py-2 px-4"  cols="12"  >
						
				<LcRecordAttribute v-if="isSizeXS" :libertas_center_id="row.item.libertas_center_id" 
				  :validator="checkPLZ" 
					:hideIconOnValue="0" attributeName="plz" icon="geo" view-class="smart-size clickableValue" />

				<LcRecordAttribute v-if="isSizeXS" :libertas_center_id="row.item.libertas_center_id" inputType="text"
				  :validator="checkContact"
					:hideIconOnValue="0" attributeName="contact" icon="telephone-outbound" view-class="smart-size clickableValue" viewTextMaxLength="16" />

				<LcRecordAttribute  :libertas_center_id="row.item.libertas_center_id" 
					:hideIconOnValue="false" attributeName="description"
					:validator="maxLength1024"
					inputType="textarea" icon="tags" view-class="smart-size clickableValue" />

      	<div v-if="isSizeXS">
				 <Erstellt  :libertas_center_id="row.item.libertas_center_id" class="smart-size"/>
				</div>
				<hr />
				<allowed-receipt-types :libertas_center_id="row.item.libertas_center_id" />
						<!-- <LcRecord :libertas_center_id="row.item.libertas_center_id"/> -->
					</b-col>
					<b-col class="p-4" cols="12" >
						<hr>
						<CommandToolbarBlocks v-if="isSizeXS" class="my-1 px-2" :libertas_center_id="row.item.libertas_center_id" />
						<CommandToolbar v-else  class="my-1 px-2" :libertas_center_id="row.item.libertas_center_id" />
					</b-col>
				</b-row>
				
      </template> 
		</b-table-lite>
		
	</b-container>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
//import Waiter from './waiter/Waiter'
import PinControl from './lcRecord/PinControl'
import Erstellt from './lcRecord/Erstellt'
//import WaiterColumn from './waiter/WaiterColumn'
import IsHot from './lcRecord/IsHot'
import LcRecordAttribute from './lcRecord/attributes/LcRecordAttribute'
import CommandToolbar from './lcRecord/CommandToolbar'
import CommandToolbarBlocks from './lcRecord/CommandToolbarBlocks'
import IconMore from 'vue-material-design-icons/CogOutline.vue'
import IconLess from 'vue-material-design-icons/UnfoldLessHorizontal.vue'
import { usernameInvalidator, requiredFieldMax256Invalidator, 
	debouncedUsernameValidation,
	min3maxLength256Invalidator, maxLength256Invalidator, plzInvalidator, maxLength1024Invalidator } from './lcRecord/validatoren'
import ModalNewLCRecord from './lcRecord/ModalNewLCRecord.vue'
import AllowedReceiptTypes from '../zulassungen/AllowedReceiptTypes.vue'

export default {
	components: { Erstellt, IsHot, LcRecordAttribute, PinControl, CommandToolbarBlocks,
		ModalNewLCRecord, CommandToolbar, IconMore, IconLess,
		AllowedReceiptTypes },
	data:  function () {
		return {
      window: {
        width: 0,
        height: 0
      }
		}
  },
	computed:{
		...mapGetters(['lcRecordsToDisplay']),
		countOfLcRecords: function(){ return this.lcRecordsToDisplay.length },
		isContainer(){ return this.countOfLcRecords < 5},
		marginTop() { return this.$store.getters.isLcRecordsPanelSticky?80:0 },
		validateUsername(){ return usernameInvalidator },
		requiredFieldMaxLength256(){ return requiredFieldMax256Invalidator },
	  verifyUsernameIsFree() { return debouncedUsernameValidation },
		maxLength256(){ return maxLength256Invalidator },
		maxLength1024(){ return maxLength1024Invalidator },
		checkPLZ(){ return plzInvalidator },
		checkContact() { return min3maxLength256Invalidator },
		isSizeXS() { return  this.window.width < 576 },
		fields() {
			return this.isSizeXS?[
				{ key: 'pinned', sortable: false, label: ''},
				{ key: 'username', sortable: false, label: this.$t('lcRecord.username.title')},
				{ key: 'title', sortable: false, label: this.$t('lcRecord.title.title')},
				{ key: 'actions', sortable: false, label: 'Actions' }
			]:
			 [
				{ key: 'pinned', sortable: false, label: ''},
				{ key: 'username', sortable: false, label: this.$t('lcRecord.username.title')},
				{ key: 'title', sortable: false, label: this.$t('lcRecord.title.title')},
				{ key: 'plz', sortable: false, label: this.$t('lcRecord.plz.title')},
				{ key: 'contact', sortable: false, label: this.$t('lcRecord.contact.title')},
				// { key: 'description', label: this.$t('waiter.description.title')},
				{ key: 'created', sortable: false, label: this.$t('created') },
			  { key: 'actions', sortable: false, label: 'Actions' }
			]
		}
		
	},
	created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
	methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }

}
</script>

<style>
#waiter_list th{
	font-weight: normal;
}
/* Small devices (landscape phones, 544px and up) */
@media (max-width: 543px) {  
  .smart-size {font-size:0.8rem;} /*1rem = 16px*/
}
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  .smart-size {font-size:0.8rem;} /*1rem = 16px*/
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .smart-size {font-size:0.9rem;} /*1rem = 16px*/
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .smart-size {font-size:1rem;} /*1rem = 16px*/
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .smart-size {font-size:1rem;} /*1rem = 16px*/    
}

</style>