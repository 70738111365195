<template>
	
	 <b-nav-item-dropdown
      id="my-nav-dropdown" slot=""
      text="Ansicht"
      toggle-class="nav-link-custom"
      right
    >
      <!-- <b-dropdown-item @click="warteraumViewModeBoard()"><board-view-icon /> Board</b-dropdown-item>
      <b-dropdown-item @click="warteraumViewModeList()"><list-view-icon /> Listen</b-dropdown-item>
			
      <b-dropdown-divider></b-dropdown-divider> -->
      
      <b-dropdown-item :active="isDESC" @click="lifo()"><lifo-view-icon /> {{$t('lcrecords.view.sort.desc')}}</b-dropdown-item>
			<!-- <b-dropdown-item disabled><fifo-view-icon /> nach Ankuftszeit</b-dropdown-item> -->
      <b-dropdown-item :active="isASC" @click="fifo()"><fifo-view-icon /> {{$t('lcrecords.view.sort.asc')}}</b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import BoardViewIcon from 'vue-material-design-icons/BulletinBoard.vue'
import ListViewIcon from 'vue-material-design-icons/FormatListCheckbox.vue'
import FifoViewIcon from 'vue-material-design-icons/SortClockAscendingOutline.vue'
import LifoViewIcon from 'vue-material-design-icons/SortClockDescendingOutline.vue'
export default {
  components: { ListViewIcon, BoardViewIcon, FifoViewIcon, LifoViewIcon },
  computed:{
    orderBy(){ return this.$store.state.lcState.orderByComparator },
    isASC(){ return this.orderBy === 'compareCreatedAsc'?true:false},
    isDESC(){ return this.orderBy === 'compareCreatedDesc'?true:false}
    
  },
  methods:{
   	lifo() {
     	this.$store.dispatch('orderByCreated', 'desc')
    },
   	fifo() {
     	this.$store.dispatch('orderByCreated', 'asc')
    },
    warteraumViewModeList() {
     	this.$store.dispatch('lcRecordsViewModeList')
    },
    warteraumViewModeBoard() {
     	this.$store.dispatch('lcRecordsViewModeBoard')
    }
	},
  

}
</script>

<style>

</style>