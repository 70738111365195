import { KEY_PERSISTENT_LOCAL_STORAGE } from './index'
import moment from 'moment'
import i18next from 'i18next'
import backend from '../backend'
import { fetchJson, fetchJsonWithBasicAuth, fetchJsonWithJWT, catchResponse401ResetAuthToken, parseJwt } from '../commons/utils'
import Either from 'data.either/lib/either'
/* Transient State: wird nicht persistiert */
const transientState = {
	state: () => ({
		mobileAppsMode: false,
		restoreFactorySettings: false,
		credentialsSupported: false,
		loading: false,
		loadingSubject: '',
		displayMomentValueOf: moment().valueOf(),
		credentials: [],
		newCredentialNickname: '',
		scrollPosition: 0,
		scrollDirectionUp: true,
		overviewReceiptsEmissionTotal: [],
		overviewReceiptsEmissionToday: [],
		overviewReceiptsEmission: [],
		overviewReceiptsEmissionDateFrom: null,
		overviewReceiptsEmissionDateTo: null
	}),
	getters:{
		credentials: state => state.credentials,
		newCredentialNickname: state => state.newCredentialNickname,
		displayMoment: state => moment(state.displayMomentValueOf),
		credentialsSupported: state => state.credentialsSupported,
		isMobileAppMode: state => state.mobileAppsMode,
		isLoading: state => state.loading,
		loadingSubject: state => state.loadingSubject,
		scrollDirectionUp: state => state.scrollDirectionUp,
		scrollPosition: state => state.scrollPosition,
		overviewReceiptsEmission: state => state.overviewReceiptsEmission,
		overviewReceiptsEmissionTotal: state => state.overviewReceiptsEmissionTotal,
		overviewReceiptsEmissionToday: state => state.overviewReceiptsEmissionToday,
		
		// isHeaderFixedTop: state => state.scrollDirectionUp && state.scrollPosition > 69,
		// isWarteraumPanelSticky: state => (state.scrollPosition && state.scrollDirectionUp) || state.scrollPosition > 69  ,
		isLcRecordsPanelSticky: state =>  state.scrollPosition > 69  ,
		//isWarteraumPanelSticky: () => true  
		isHeaderFixedTop: () => true,

	},
	mutations: {
		credentials: (state, payload) => { state.credentials = payload },
		overviewReceiptsEmissionDateTo: (state, payload) => { state.overviewReceiptsEmissionDateTo = payload },
		overviewReceiptsEmissionDateFrom: (state, payload) => { state.overviewReceiptsEmissionDateFrom = payload },
		overviewReceiptsEmission: (state, payload) => { state.overviewReceiptsEmission = payload },
		overviewReceiptsEmissionTotal: (state, payload) => { state.overviewReceiptsEmissionTotal = payload },
		overviewReceiptsEmissionToday: (state, payload) => { state.overviewReceiptsEmissionToday = payload },
		newCredentialNickname: (state, payload) => { state.newCredentialNickname = payload },
		restoreFactorySettings: (state) => { state.restoreFactorySettings = true },
		scrollPosition: (state, payload) => { 
			state.scrollDirectionUp = state.scrollPosition > payload 
			state.scrollPosition = payload
		},
		displayMoment (state, payload = moment()) { state.displayMomentValueOf = payload.valueOf() },
		setupAppRuntimeSettings: (state) => {
			state.mobileAppsMode = window.cordova?true:false
			state.credentialsSupported = 'credentials' in navigator
		},
		setLoading(state, payload) { state.loading = payload?true:false} ,
		setLoadingWithSubject: (state, payload) => { 
			if(!state.loading) state.loading = true
			state.loadingSubject = payload
		},
		loadCompleted: (state) => { state.loading = false },
	},
	actions: {
		test: (ctx) => console.log('transientState - action'),
		newCredentialNickname(ctx, payload ){ 
			ctx.commit('newCredentialNickname', payload)
		},
		loadOverviewReceiptsEmissionTotal: async(ctx) => {
			const dateFrom = '2000-01-01'
			const dateTo = '2199-12-31'
			
			ctx.commit('setLoading', true)
			
			// Über ganze Zeit
			const result = await loadOverviewReceiptsEmission(ctx, dateFrom, dateTo)
			if(result.isRight){
				await ctx.dispatch('setOverviewReceiptsEmissionTotal', result.value)
			}

			// Heutige Zahlen
			const resultToday = await loadOverviewReceiptsEmission(ctx)
			if(resultToday.isRight){
				await ctx.dispatch('setOverviewReceiptsEmissionToday', resultToday.value)
			}

			ctx.commit('setLoading', false)
		},
		setOverviewReceiptsEmissionTotal: (ctx, payload) => {
			//console.log(payload)
			ctx.commit('overviewReceiptsEmissionTotal', payload)
		},
		setOverviewReceiptsEmissionToday: (ctx, payload) => {
			//console.log(payload)
			ctx.commit('overviewReceiptsEmissionToday', payload)
		}
		
	}
}

const extractFromCtx = (ctx) => {
	return { backendURL : ctx.rootGetters.backendURL, jwt : ctx.rootGetters.kkJWT }
}

const dateToISO = date => {
	const m = moment(date, moment.ISO_8601)
	return m.toISOString()
}

const loadOverviewReceiptsEmission = async(ctx, dateFrom = new Date(), dateTo = new Date()) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	const parameterDateFrom = `date_from=${encodeURI(dateToISO(dateFrom))}`
	const parameterDateTo = `date_to=${encodeURI(dateToISO(dateTo))}`

	const url = `${backendURL}/${backend.URL_GET_OVERVIEW_RECEIPTS_EMISSION}?${parameterDateFrom}&${parameterDateTo}`
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}
export default transientState