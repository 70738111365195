<template>
	<table class="table table-striped table-sm smart-size" v-if="isDataExists">
		<thead>
			<tr>
				<th class="text-left" scope="col">Belegart</th>
				<th scope="col">Heute</th>
				<th scope="col">Insgesamt</th>
			</tr>
		</thead>
		<tbody>
			<tr  v-for=" row in overviewReceiptsEmissionTotal" :key="row.beleg_art">
				<td class="text-left">{{row.beleg_art}} </td>
				<td><TodayKennzahl :beleg_art="row.beleg_art" /> </td>
				<td>{{row.anzahl}}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TodayKennzahl from './TodayKennzahl.vue'
export default {
	components : { TodayKennzahl
	},
	computed:{
		...mapGetters([ 'overviewReceiptsEmissionTotal' ]),
		isDataExists(){ return !!this.overviewReceiptsEmissionTotal.length }
	},
//
}
</script>

<style>

</style>