<template>
<tr >
		<td class="text-left px-4 " >
		<div class="h6" v-bind:class="{'font-weight-bold text-primary h5' : isAlreadyExist}">
			<CredentialNickname  :cred_id="credential.cred_id" :viewValue="credential.nickname" viewClass="clickableValue" />
		</div>
		<div class="small">
			<span :title="credential.cred_id">{{$t('cred.id')}} {{credId}}</span><br>
			{{$t('cred.created')}} {{created}}
		</div>
		</td>
		<td class="text-right px-4"><b-button variant="outline-danger" @click="deleteCredential()" size="sm">{{$t('delete')}}</b-button></td>
</tr>	
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

import CredentialNickname from '../webauthn/CredentialNickname'
export default {
	props: ['credential'],
	components:{ CredentialNickname },
	computed:{
		...mapGetters(['newCredentialNickname']),
		isAlreadyExist: function(){
			return this.credential.nickname === this.newCredentialNickname.trim()
		},
		credId() { 
			const postfix = this.credential.cred_id.length > 16?"...":""
			return this.credential.cred_id.substring(0,16) + postfix 
		},
		created() { return moment(this.credential.created).format('D.MM.YYYY HH:mm') }
		
	},
	methods:{
		async deleteCredential(){
			const resp = await this.$store.dispatch('deleteCredential', this.credential.cred_id)
			if(resp.isRight){
				this.$store.dispatch('setFeedbackMessage', {message: this.$t('token.deleted.message'), variant:'info', ttl:12200})
			}
			await this.$store.dispatch('retrieveCredentials')
		}
	}

}
</script>

<style>

</style>