<template>
	<span v-if="anzahl">{{anzahl}} </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	props: [ 'beleg_art' ],
	components : { 
	},
	computed:{
		...mapGetters([ 'overviewReceiptsEmissionToday' ]),
		anzahl(){ 
			const row = this.overviewReceiptsEmissionToday.find( r => r.beleg_art === this.beleg_art )
			return row?row.anzahl:null }
	}
}
</script>

<style>

</style>