<template>
	<span v-if="erstellt">{{erstellt}}</span>
</template>
<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
export default {
	props:[ 'libertas_center_id' ],
	computed:{
		...mapGetters(['lcRecords']),
		lcRecord(){ return this.lcRecords.find( r => r.libertas_center_id === this.libertas_center_id) },
		erstellt(){ 
			return this.lcRecord.created?moment(this.lcRecord.created).format('DD.MM.YYYY HH:mm'):undefined },
	}
}
</script>

<style>

</style>