<template>
<div>
	<p class="small text-left">{{$t('admin.account.description')}}</p>
	
	<b-form-group class="text-left"
		
		label-align="left"
		label-align-sm="right"
		id="loginFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('lcRecord.admin_account.title')"
		label-for="input-admin_account"
				>
		<b-form-checkbox switch class="mx-2 py-2" v-model="adminAccount">
			<span class="sr-only">Switch for admin account setting</span>
		</b-form-checkbox> 
		
	</b-form-group>


	<b-input-group>
		<b-form-text></b-form-text>
	</b-input-group>
</div>
</template>

<script>
export default {
		computed:{
			adminAccount: {
			get () {
        return this.$store.getters.nlcdAdminAccount === 1
      },
      set (value) {
				this.$store.dispatch('nlcdAdminAccount', value)
      }
		}
		
	},

}
</script>

<style>

</style>