<template>
<b-container class="setup-pages" fluid="md">
	
	<h3>{{$t('initial.setup.title')}}</h3>
	 <Intro v-if="isInto" />
	 <Login v-if="isLogin" />
	 <terms-of-use v-if="isTermsOfUse" />
	 
</b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Intro from '@/components/setup/Intro'
import Login from '@/components/setup/Login'
import TermsOfUse from './setup/TermsOfUse.vue'
export default {
	computed: {
		...mapGetters(['setupStep']),
		isInto(){ return this.setupStep === 'init' },
		isLogin(){ return this.setupStep === 'login' },
		isTermsOfUse(){ return this.setupStep === 'termsOfUse' },
	},
	components: { Intro, Login, TermsOfUse }
}
</script>
<style>
.setup-pages{
	padding-top: 116px;
}
</style>