import moment from 'moment'
export default {

	 isHot: (lcRecord, displayMoment, hotDurationMinutes) => {
		if(hotDurationMinutes <= 0) return false
		const momentHotUntil = moment(lcRecord.created).add(hotDurationMinutes, 'm')
		return displayMoment.isBefore(momentHotUntil)
	 },
	 	 
	comparePinned : ( a, b ) => {
		const pa = a.pinned?true:false
		const pb = b.pinned?true:false
		if ( pa && !pb ){
			return -1;
		}
		if ( !pa && pb ){
				return 1;
			}
			return 0;
	},
			
	compareCreatedAsc : ( a, b ) => {
		if ( a.created > b.created ){
			return 1;
		}
		if ( a.created < b.created ){
		return -1;
		}
		return 0;
	},
	compareCreatedDesc : ( a, b ) => {
		if ( a.created > b.created ){
			return -1;
		}
		if ( a.created < b.created ){
			return 1;
		}
		return 0;
	}
}