<template>
<div v-if="isMobileAppMode">
	<p class="small text-left">{{$t('prefer.front.camera.description')}}</p>
	
	<b-form-group class="text-left"
		
		label-align="left"
		label-align-sm="right"
		id="preferfromcameraFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('prefer.front.camera.label')"
		label-for="input-preferfrontcamera"
				>
		<b-form-checkbox switch class="mx-2 py-2" v-model="preferFrontCamera">
			<span class="sr-only">prefer front camera</span>
		</b-form-checkbox> 
		
	</b-form-group>


	<b-input-group>
		<b-form-text></b-form-text>
	</b-input-group>
</div>
</template>

<script>
export default {
		computed:{
			isMobileAppMode(){ return this.$store.getters.isMobileAppMode },
			preferFrontCamera: {
			 get () {
        return this.$store.getters.preferFrontCamera
      },
      set (value) {
				this.$store.dispatch('preferFrontCamera', value)
      }
		}
		
	},

}
</script>

<style>

</style>