<template>
	<div v-bind:class="{ 'with-header-offset': withHeaderOffset}" >
		
		<b-container class=" mt-2" fluid="xl">
			<FeedbackMessage />
			
		</b-container>
			<h4>{{$t('dashboard')}}</h4>
			
			<div class="app-panel container bg-white my-4 py-2">
				<AktuellesGeschehen v-if="isAdminAccount" class="my-4" />
				<hr v-if="isAdminAccount" />
				<AdministrationControl v-if="isAdminAccount" class="my-4" />
				<hr v-if="isAdminAccount" />
				<LibertasCenterControl class="my-4" />
			</div>
	</div>	
</template>

<script>
import FeedbackMessage from '../FeedbackMessage'
import moment from 'moment'
import Either from 'data.either/lib/either'
import LibertasCenterControl from './libertasCenter/DashboardControl'
import AdministrationControl from './admin/DashboardControl'
import AktuellesGeschehen from './overview/DashboardControl'
import momentDurationFormatSetup from "moment-duration-format"
import { mapGetters, mapState } from 'vuex'
import QrCodeModal from '../qrcodereader/QrCodeModal.vue'
export default {
	components : { FeedbackMessage, LibertasCenterControl, AdministrationControl, AktuellesGeschehen,
QrCodeModal,
	 },
	computed:{
		...mapGetters([ 'isAdminAccount' ]),
		withHeaderOffset() { return this.$store.getters.isHeaderFixedTop }
	},
	created() {
		momentDurationFormatSetup(moment)
		this.handleIntervalDisplayMoment = setInterval(() => { this.$store.commit('displayMoment', moment()) }, 1000)
	},
	async mounted(){
		await this.$store.dispatch('loadReceiptTypes')
		await this.$store.dispatch('loadOwnDetails')
		if(this.isAdminAccount){
			await this.$store.dispatch('pullOverviewKeyFigures')
			await this.$store.dispatch('loadOverviewReceiptsEmissionTotal')
		} 
	},
  activated(){ },
	beforeDestroy() {
   	clearInterval(this.handleIntervalDisplayMoment)
  },
	methods:{
		
	}

}
</script>
<style scoped>
.with-header-offset{
	padding-top: 76px;
}
</style>