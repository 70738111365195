<template>
      <new-box-icon v-if="isHot" :class="viewClass" />
</template>

<script>
import NewBoxIcon from 'vue-material-design-icons/NewBox.vue'
import lcRecordUtil from '../../../admin/lcRecords/lcRecordUtil'
import { mapGetters, mapState } from 'vuex'
export default {
  props: ['libertas_center_id', 'viewClass'],
  components: { NewBoxIcon },
  computed:{
     ...mapGetters(['lcRecords']),
		lcRecord(){ return this.lcRecords.find( r => r.libertas_center_id === this.libertas_center_id) },
    displayMoment(){ return this.$store.getters.displayMoment },
    hotDurationMinutes(){ return this.$store.getters.hotDurationMinutesLcRecords },
    isHot() { 
      return lcRecordUtil.isHot(this.lcRecord, this.displayMoment, this.hotDurationMinutes)
    }
  }
  
}
</script>

<style>
</style>