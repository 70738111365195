<template>
  <div id="app">
    <AppHeader class="border-bottom"/>
    <Loader v-if='isLoading' />
    <b-container fluid="dm">
      <InitialSetup v-if='!isSetupCompleted'/>
    </b-container>
      <LoginScreen v-if='forceLogin && isSetupCompleted' />
      <Dashboard v-else-if='isScreenDashboard'/>
      <LcRecordsScreen v-else-if='isScreenLcRecords && isAdminAccount'/>
      <Settings v-else-if='isScreenSettings'/>
      
      <About v-else-if='isScreenAbout'/>
      <Impressum v-else-if='isScreenImressum'/>
      <PrivacyPolicy v-else-if="isScreenPrivacyPolicy" />
      
    
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import { request, requestJWT, parseJwt } from '@/commons/utils'
import AppHeader from '@/components/AppHeader'
import Dashboard from '@/components/client/Dashboard'
import LcRecordsScreen from './components/lcRecords/LcRecordsScreen'
import About from '@/components/About'
import InitialSetup from '@/components/InitialSetup'
import Settings from '@/components/settings/Settings'

import LoginScreen from '@/components/screens/Login'
//import Backend from '@/backend'

import { mapGetters } from 'vuex'
import _throttle from 'lodash/throttle'

export default {
  name: 'App',
  computed:{
     ...mapGetters(['currentStep', 'isLoading', 'isSetupCompleted', 'kkJWT', 'isScreenDashboard', 
     'isScreenLcRecords', 'isScreenReceipts', 'isAdminAccount',
     'isScreenSettings', 'isScreenAbout', 'isScreenImressum', 'isScreenPrivacyPolicy']),
     forceLogin() { return this.kkJWT?false:true }
  },
  components: {
   Loader, AppHeader, InitialSetup, Dashboard, LcRecordsScreen,
   Settings, About, LoginScreen
  },
  async mounted() {
   
    // DEBUG expose als butlerKC
    window.libertas = this
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    
    handleScroll(event){
      //this.$store.commit('scrollPosition', window.scrollY )
      throttledCommit(this.$store)
    }
  },
}
const throttledCommit = _throttle((store) => { store.commit('scrollPosition', window.scrollY )}, 300)
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  /* margin-top: 3rem; */
}
</style>
