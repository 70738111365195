<template>
	<b-icon font-scale="2.28" variant="info" :icon="icon" class="border-none bg-light-none border-info rounded p-2 clickableValue">
	</b-icon> 
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
	data: function () {
		return {}
	},
	props: ['icon']

}
</script>

<style>

</style>