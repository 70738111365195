import Either from 'data.either'
import backend from '../backend'
import i18next from '../i18n/i18n'
import moment from 'moment'
import { nanoid } from 'nanoid'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'debounce'
import { fetchJson, fetchJsonWithBasicAuth, fetchJsonWithJWT, catchResponse401ResetAuthToken, parseJwt } from '../commons/utils'
import { filterLcRecords, isViewActive, viewFilterSet } from './administrationUtil'
import lcRecordUtil from '../admin/lcRecords/lcRecordUtil'

const modulState = {
	state: () => ({
		adminAccount: false,
		userId: '',
		userName: '',
		receiptTypes: [],
		defaultAllowedReceiptTypes: [],
		defaultAllowedReceiptTypesInitialized: false,
		orgaName: i18next.t('orga.name.default'),
		plz: '',
		contact: '',
		overviewKeyFigures: {},
		limitLcRecordsToLoading: 128,
		modalAnmeldedatenLcId: '',
		lcRecords: [],
		displayFilterStack: [ 'filterDeleted', 'filterDimissed' ],
		//filterOrphaned: 'filterOrphaned',
		filterOrphaned: '',
		orderByComparator: 'compareCreatedDesc',
		lcRecordIdOfModalEdit: -1,
		lastUpdateDbClockTimeISOString: null,
		maxAllowedLcRecords: 156,
		maxAllowedLcRecordsInView: 124,
		searchString: '',
		hotDurationMinutes: 10,
		valueOfActivationCodeValidityDuration: moment.duration(20, 'minutes').valueOf(),
		viewMode: 'list'
	}),
	getters:{
		isDefaultAllowedReceiptTypesInitialized: state => state.defaultAllowedReceiptTypesInitialized,
		modalAnmeldedatenLcId: state => state.modalAnmeldedatenLcId,
		defaultAllowedReceiptTypes: state => state.defaultAllowedReceiptTypes,
		lcRecordsViewMode: state => state.viewMode,
		displayFilterStack: state => state.displayFilterStack,
		lcRecordsFilterOrphaned: state => state.filterOrphaned,
		hotDurationMinutesLcRecords: state => state.hotDurationMinutes,
		lcRecordsSearchString: state => state.searchString,

		lcRecordOfModalEdit: (state) => {
			const lcRecords = state.lcRecords
			let index = lcRecords.findIndex(r => r.libertas_center_id === state.lcRecordIdOfModalEdit)
			if(index < 0) index = 0
			const lcRecord = state.lcRecords[index]
			return lcRecord
		},

		isViewMainView: state => isViewActive( state.displayFilterStack, viewFilterSet.mainView),
		isViewConfirmed: state => isViewActive( state.displayFilterStack, viewFilterSet.confirmed),
		isViewToBeConfirmed: state => isViewActive( state.displayFilterStack, viewFilterSet.toBeConfirmed),
		isViewPapierkorb: state => isViewActive( state.displayFilterStack, viewFilterSet.papierkorb),

		lcRecordsOfMainView: (state, getters, rootState) => filterLcRecords(viewFilterSet.mainView, rootState),
		lcRecordsOfConfirmed: (state, getters, rootState) => filterLcRecords(viewFilterSet.confirmed, rootState),
		lcRecordsOfToBeConfirmed: (state, getters, rootState) => filterLcRecords(viewFilterSet.toBeConfirmed, rootState),
		lcRecordsOfPapierkorb: (state, getters, rootState) => filterLcRecords(viewFilterSet.papierkorb, rootState),

		activationCodeValidityDuration: state =>  moment.duration(state.valueOfActivationCodeValidityDuration),
		defaultLcRecord: state => getDefaultLcRecord(state),
		limitLcRecordsToLoading: state => state.limitLcRecordsToLoading,
		lcRecordsLastUpdateTimestamp: state => state.lastUpdateDbClockTimeISOString,
		lcRecords: state => state.lcRecords,
		orphanedLcRecords: state => orphanedFilter(state.lcRecords),

		isAdminAccount: state => state.adminAccount,
		userId: state => state.userId,
		receiptTypes: state => state.receiptTypes,
		orgaName: state => state.orgaName,
		userName: state => state.userName,
		overviewKeyFigures: state => state.overviewKeyFigures,

		filteredSortedLcRecords: (state, getters, rootState) => {
			
			let filteredLcRecords = filterLcRecords(state.displayFilterStack, rootState)
			
			if(state.searchString.trim()){
				const searchString =  state.searchString
				filteredLcRecords = searchFilter(filteredLcRecords, searchString)
			}
			
			if(state.orderByComparator){
				//console.log("comparator set", state.orderByComparator)
				const comparator = lcRecordUtil[state.orderByComparator]
				const hotComparator = getHotComparator( moment(rootState.appState.displayMomentValueOf), state.hotDurationMinutes)
				const filteredSorted = filteredLcRecords.sort(comparator).sort(lcRecordUtil.comparePinned).sort(hotComparator)
				return filteredSorted
			}
			return filteredLcRecords
		},
		lcRecordsToDisplay: (state, getters, rootState) => { 
			// restrict count of objects in view
			return getters.filteredSortedLcRecords.slice(0, state.maxAllowedLcRecordsInView)
		},

		showWarningMaxAllowedLcRecords: (state, getters, rootState) => getters.lcRecords.length > state.maxAllowedLcRecords,
		showWarningMaxAllowedLcRecordsInView: (state, getters, rootState) => getters.filteredSortedLcRecords.length > state.maxAllowedLcRecordsInView,

	},
	mutations: {
		setDefaultAllowedReceiptTypesInitialized: (state, payload) => { state.defaultAllowedReceiptTypesInitialized = payload },
		setDefaultAllowedReceiptTypes: (state, payload) => { state.defaultAllowedReceiptTypes = payload },
		modalAnmeldedatenLcId: (state, payload) => { state.modalAnmeldedatenLcId = payload },
		lcRecordsViewMode: (state, payload) => { state.viewMode = payload },
		lcRecordsFilterOrphaned: (state, payload) => { state.filterOrphaned = payload },
		hotDurationMinutesLcRecords: (state, payload) => { state.hotDurationMinutes = payload },
		lcRecordsSearchString: (state, payload) => { state.searchString = payload },
		orderByComparator: (state, payload) => { state.orderByComparator = payload },
		displayFilterStack: (state, filterStack) => { state.displayFilterStack = filterStack },
		lcRecordIdOfModalEdit(state, payload) { state.lcRecordIdOfModalWartsAbBarcode = payload	},

		killLcRecord: (state, libertas_center_id) => { state.lcRecords = state.lcRecords.filter((item) => item.libertas_center_id !== libertas_center_id) },

		lcUserId(state, payload) { state.userId = payload },
		lcOverviewKeyFigures(state, payload) { state.overviewKeyFigures = { ...payload } },
		lcAdminAccount(state, payload) { state.adminAccount = payload?true:false },
		lcReceiptTypes(state, payload) { state.receiptTypes = payload?payload:[] },
		lcOwnDetails(state, payload) {
			state.userId = payload.libertas_center_id
			state.orgaName = payload.title
			state.plz = payload.plz
			state.contact = payload.contact
			state.userName = payload.username
			state.adminAccount = payload.admin_account === 1
		},
		orgaName (state, payload) { state.orgaName = payload },
		lastUpdateDbClockTimeISOString: (state, payload) => {
			state.lastUpdateDbClockTimeISOString = payload
		},
		mergeLcRecord: (state, payload = {}) => {
			const index = state.lcRecords.findIndex(r => r.libertas_center_id === payload.libertas_center_id)
			if(index >= 0){
				// update
				console.log('updating with', payload)
				state.lcRecords[index] = {...state.lcRecords[index], ...payload}
				state.lcRecords = [...state.lcRecords]
			}else{
				console.log('push', payload)
				const defaultLcRecord = getDefaultLcRecord()
				state.lcRecords.push({...defaultLcRecord, ...payload})
			}
		}
  },
	actions: {
		test: (ctx) => console.log('lcState - action'),

		lcRecordsViewModeList(ctx){ 
			ctx.commit('lcRecordsViewMode', 'list')
		},
		lcRecordsViewModeBoard(ctx){ 
				ctx.commit('lcRecordsViewMode', 'board')
		},
		setLcRecordsSearchString(ctx, payload ){ 
				ctx.commit('lcRecordsSearchString', payload)
		},
		setShowOrphanedLcRecords(ctx, payload ){ 
				const filter = payload?'':'filterOrphaned'
				ctx.commit('lcRecordsFilterOrphaned', filter)
		},
		orderByCreated( ctx, ascOrDesc ){
			let orderByComparatorName = undefined
			switch (ascOrDesc){
				case 'asc':
					orderByComparatorName = 'compareCreatedAsc'
					break
				case 'desc':
					orderByComparatorName = 'compareCreatedDesc'
					break
				default:
					orderByComparatorName = undefined
			}
			ctx.commit('orderByComparator', orderByComparatorName)

		},
		setDisplayFilterStack( ctx, filterStack ){
			ctx.commit('displayFilterStack', filterStack)
		},
		addDisplayFilter( ctx, filterName ){
			if(ctx.state.displayFilterStack.indexOf(filterName) < 0){
				ctx.commit('displayFilterStack', [...ctx.state.displayFilterStack, filterName])
			}
		},
		removeDisplayFilter(ctx, filterName ){
			if(ctx.state.displayFilterStack.indexOf(filterName) >= 0){
				ctx.commit('displayFilterStack', ctx.state.displayFilterStack.filter((f) => f !== filterName))
			}
		},
		hotDurationMinutesLcRecords( ctx, hotDurationMinutes){
			ctx.commit('hotDurationMinutesLcRecords', hotDurationMinutes)
		},
		
    pullOverviewKeyFigures: async (ctx) => {
			ctx.commit('setLoading', true)
			const responseDataLoading = await loadKeyFigures(ctx)
			if( responseDataLoading.isRight ){
					const overviewKeyFigures = responseDataLoading.value
					ctx.commit('lcOverviewKeyFigures', overviewKeyFigures)
					// console.debug('lcOverviewKeyFigures:', overviewKeyFigures)
			}
			ctx.commit('setLoading', false)
			return responseDataLoading
		},
		loadReceiptTypes: async (ctx) => {
			const { jwt } = extractFromCtx(ctx)
			ctx.commit('setLoading', true)
			const responseDataLoading = await loadReceiptTypes(ctx, jwt)
			if( responseDataLoading.isRight ){
					const receiptTypes = responseDataLoading.value
					ctx.commit('lcReceiptTypes', receiptTypes)
					if(!ctx.getters.isDefaultAllowedReceiptTypesInitialized){
						//console.log('init default for receipt types', receiptTypes)
						const defaultAllowedReceiptTypes = []
						receiptTypes.forEach( e => defaultAllowedReceiptTypes.push(e.receipt_type_id))
						ctx.commit('setDefaultAllowedReceiptTypes', defaultAllowedReceiptTypes)
						ctx.commit('setDefaultAllowedReceiptTypesInitialized', true)
					}
					//console.debug('Loaded receipt types:', receiptTypes)
			}
			ctx.commit('setLoading', false)
			return responseDataLoading
		},
		receiptTypeZulassung: async (ctx, payload) => {
			const {libertas_center_id, allowed_receipt_types, receipt_type_id, zulassung } = payload
			let new_allowed_receipt_types = [...payload.allowed_receipt_types]
			if(zulassung){
				if(new_allowed_receipt_types.indexOf(receipt_type_id) < 0)	new_allowed_receipt_types.push(receipt_type_id)
			}else{
				new_allowed_receipt_types = new_allowed_receipt_types.filter((t) => t !== receipt_type_id)
			}
			//console.log("new set", new_allowed_receipt_types)
			const lcRecord = ctx.state.lcRecords.find( w => w.libertas_center_id === libertas_center_id) 
			const profile = lcRecord.profile?{...lcRecord.profile}:{}
			profile.allowed_receipt_types = new_allowed_receipt_types
			const patchPayload = { libertas_center_id, patch: { profile } }
			const result = await ctx.dispatch('patchAttributeOfLcRecord', patchPayload)
			return result
		},
		loadOwnDetails: async (ctx) => {
			ctx.commit('setLoading', true)
			const responseDataLoading = await loadLibertasCenterData(ctx)
			if( responseDataLoading.isRight ){
					const ownDetails = responseDataLoading.value
					ctx.commit('lcOwnDetails', ownDetails)
					// console.debug('Loaded own details:', ownDetails)
			}
			ctx.commit('setLoading', false)
			return responseDataLoading
		},
		patchAttributeOfLcRecord: async (ctx, payload) => {
			ctx.commit('setLoading', true)
			//console.log("action patch called:", payload)
			const response = await patchLcRecord(ctx, payload)
			catchResponse401ResetAuthToken(ctx, response)
			ctx.commit('setLoading', false)
			return response
		},

		deleteLcRecord: async (ctx, payload) => {
			ctx.commit('setLoading', true)
			console.log("action delete called:", payload)
			const result = await deleteLcRecord(ctx, payload)
			console.log("response deleting:", result)
			catchResponse401ResetAuthToken(ctx, result)
			ctx.commit('setLoading', false)
			if(result.isRight){
				if( 1 === result.value.affectedRows ) ctx.commit('killLcRecord', payload.libertas_center_id)
			}else{
				// status 404 -> prüfen ob in Response auch json ist (falls bereits gelöscht)
				if(result.value.response.status === 404){
					try{
						const json = await result.value.response.json()
						if( 0 === json.affectedRows ) ctx.commit('killLcRecord', payload.libertas_center_id)
						return Either.Left(json)
					}catch(e){
						// do nothing, kein json in der Antwort wird result zurüclgegeben
					}
				}
			}
			return result
		},

    updateLcRecords: async (ctx) => {
			const authorizationToken = ctx.rootGetters.authorizationToken
			
			ctx.commit('setLoading', true)
			const response = await loadLcRecords(ctx, authorizationToken)
			catchResponse401ResetAuthToken(ctx, response)
			ctx.commit('setLoading', false)

			if(response.isRight){
				mergeReceivedLcRecords(ctx, response.value)
			}
			return response
		}
		
		/*
		...
		if( respLogin.isLeft ){
			const left = respLogin.value
			if(left.error && left.error.stack && left.error.stack === 'TypeError: Failed to fetch'){
				ctx.commit('feedbackMessage', { message:i18next.t('common.network.error'), variant:'danger' })
			}
			if(left.response && left.response.status && left.response.status === 401){
				ctx.commit('feedbackMessage', { message: i18next.t('login.401') })
			} 
		}
		*/
		
	}
}
export default modulState

const mergeReceivedLcRecords = (ctx, receivedLcRecords) => {
	receivedLcRecords.forEach(r => {
		ctx.commit('mergeLcRecord', r)
	});
}

export const getDefaultLcRecord = () => {
	const defaultLcRecord = { 
		libertas_center_id: uuidv4(),
		username: `lc-${nanoid().substr(0, 6)}`
	}
	return defaultLcRecord
}
const extractFromCtx = (ctx) => {
	return { backendURL : ctx.rootGetters.backendURL, jwt : ctx.rootGetters.kkJWT }
}

const loadLibertasCenterData = async(ctx) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	const url = `${backendURL}${backend.URL_GET_DATA_LIBERTAS_CENTER}`
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const dataLibertasCenter = result.value.json
			return Either.Right(dataLibertasCenter)
		}
	}
	return result
}
/**
 * incremental update of lcRecords status from backend server
 * @param ctx - context of vuex used for getting and updating of lastUpdateTimestamp
 */
 const loadLcRecords = async (ctx) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	const limit = ctx.getters.limitLcRecordsToLoading
	const lastUpdate = ctx.getters.lcRecordsLastUpdateTimestamp
	const since = moment.utc(lastUpdate, moment.ISO_8601).valueOf()

	const url = `${backendURL}${backend.URL_GET_LC_RECORDS}?limit=${limit}${since?'&since='+encodeURIComponent(since):''}`
	//console.debug(url)
	
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const lcRecordsFromServer = result.value.json
			if( lcRecordsFromServer.length > 0 ) updateLastUpdatedTimestamp(ctx, result.value.response)
			return Either.Right(lcRecordsFromServer)
		}
	}
	return result
}

const patchLcRecord = async (ctx, payload) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	
	const url = `${backendURL}${backend.URL_POST_PATCH_DELETE_LC_RECORD}/${encodeURIComponent(payload.libertas_center_id)}`
	//console.debug(url)
	
	const result = await fetchJsonWithJWT(jwt, url, 'PATCH', payload.patch)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}
const deleteLcRecord = async (ctx, payload) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	
	const url = `${backendURL}${backend.URL_POST_PATCH_DELETE_LC_RECORD}/${encodeURIComponent(payload.libertas_center_id)}`
		
	const result = await fetchJsonWithJWT(jwt, url, 'DELETE')
	// status 200 ist interessant
	console.log("lcDeleteAction: ", result)
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}

function getHotComparator(displayMoment, hotDurationMinutes){
	const dMoment = displayMoment
	const hdm = hotDurationMinutes
	const compareHot = function( a, b ){
	 const pa = lcRecordUtil.isHot(a, dMoment, hdm)
	 const pb = lcRecordUtil.isHot(b, dMoment, hdm)
	 if ( pa && !pb ){
		 return -1;
		}
		if ( !pa && pb ){
			return 1;
		}
		return 0;
	}
	return compareHot
}

const loadKeyFigures = async(ctx) => {
	const { backendURL, jwt } = extractFromCtx(ctx)
	const url = `${backendURL}${backend.URL_GET_OVERVIEW_KEY_FIGURES}`
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			const loadedJSON = result.value.json
			return Either.Right(loadedJSON)
		}
	}
	return result
}

/**
 * loads all receipt_type
 * @param ctx - context of vuex 
 * @param authorizationToken - authorizationToken
 */
const loadReceiptTypes = async (ctx, authorizationToken) => {
	const limit = 0
	const url = `${ctx.getters.backendURL}${backend.URL_GET_RECEIPT_TYPE}?limit=${limit}`
		
	const result = await fetchJsonWithJWT(authorizationToken, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const purposesRows = result.value.json
			const purposes = purposesRows.map( row => { 
				//const {id, purpose } = row
				return { ...row }
			})
			return Either.Right(purposes)
		}
	}
	return result
}

/**
 * extrahiert response header service_db_clock_timestamp und speichers das in State unter lastUpdateTimestamp
 * @param {*} ctx - context of vuex
 * @param {*} response - server response with headers to pickup service_db_clock_timestamp
 */
const updateLastUpdatedTimestamp = (ctx, response) => {
	let headerDbClockTimstamp = response.headers.get('service_db_clock_timestamp')
	if(! headerDbClockTimstamp ){
		// fallback, use own clock
		headerDbClockTimstamp = moment().toISOString()
	}
	const dbClockTime = moment.utc(headerDbClockTimstamp, moment.ISO_8601)
	const lastUpdateDbClockTimeISOString = dbClockTime.toISOString()
	ctx.commit('lastUpdateDbClockTimeISOString', lastUpdateDbClockTimeISOString)
	//console.debug('lastUpdateDbClockTimeISOString', lastUpdateDbClockTimeISOString)
	
	
}

const lcRecordToString = r => {
	const { title = '', username, contact = '', description = '', plz = '' } = r
 return	`${title} ${username} ${contact} ${description} ${plz} `
}

const trashFilter = (lcRecords) => lcRecords.filter((r) => r.deleted?true:false)
const pinnedFilter = (lcRecords) => lcRecords.filter((r) => r.pinned?true:false)
const orphanedFilter = (lcRecords) => lcRecords.filter((r) => r.password?false:true)

const searchFilter = (lcRecords, searchString) => {
	if(!searchString) return lcRecords
		const searchTerms = searchString.toLowerCase().split(' ')
	return lcRecords.filter( r  => {
		const lcRecordAsString = lcRecordToString(r).toLowerCase()
		let found = true
		searchTerms.forEach(
			term => { 
				let match = r.libertas_center_id === term
				if(!match) match = lcRecordAsString.indexOf(term) >= 0
				found = found && match
			}
			)
		return found 
	})
}


