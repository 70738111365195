<template>
	<div  v-bind:class="{ 'with-header-offset': withHeaderOffset}">
		<div class="m-4">
			<h3>{{$t('server.authorisation.title')}}</h3>
		</div>

	<b-container class="app-panel bg-white container px-4">
	<p class="lead my-4">{{$t('login.lead.message', { orgaName}) }}</p>
	
	<FeedbackMessage/>
	<b-form v-on:submit.prevent="" class="mb-4">
		<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group
					id="loginFormGroup"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('setup.login.label.login')"
					label-for="input-login"
				>
				<span class="form-control text-left font-weight-bold">{{kkLoginName}}</span>
					
				</b-form-group>
			</b-col>
		</b-row>

		<b-row class="form-group" v-if="loginWithPassword">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="pwd"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('setup.login.label.password')"
					:description="$t('setup.login.tipp')"
					label-for="input-password"
				>
					<b-form-input id="input-password" @keyup="keyUpOnInput()" :disabled="inputsDisabled" ref="inputPassword" v-model="servicePassword" autocomplete="false" type="password" />
				</b-form-group>
			</b-col>
		</b-row>
	</b-form>
	<b-row class="d-flex" align-h="between">
		<div class="col-md-6 col-lg-4 col-sm-12 text-left my-2">
			<b-button block class="btn d-none" v-if="credentialsSupported && credentialsAvailable"
			variant="primary" @click="loginWebauthn()">{{$t('button.login.with.token')}}<secure-key-icon :size="21" /></b-button>
			
		</div>
		<div class="col text-right my-2">
			<b-button class="mb-2 ml-2" v-if="!loginWithPassword" variant="light" @click="allowLoginWithPassword()">{{$t('button.login.with.password')}}</b-button>
			<b-button class="mb-2 mr-4 ml-2 mr-2"  v-if="isMobileAppMode" variant="info" @click="scanServiceBarcode()">
				<datamatrix-scan-icon :size="18" class="text-light" />
			</b-button>
			<b-button class="mb-2 mr-4 ml-2 mr-2"  v-else variant="info" @click="scanQRCode()">
				<datamatrix-scan-icon :size="18" title="Scan QRCode" class="text-light" />
			</b-button>
			<b-button class="mb-2 ml-2" :disabled="!interactiveLoginEnabled" v-if="loginWithPassword" variant="primary" @click="login()">{{$t('welcome.intro.button.login')}}</b-button>
		</div>
	</b-row>
		
 </b-container>	
 <QrCodeModal id="loginScanQR" :onBarcodeDetected="qrCodefound" 
		:subject="$t('login.qrcode.scan.subject')" :title="$t('login.qrcode.scan.title')"></QrCodeModal>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FeedbackMessage from '@/components/FeedbackMessage'
import DatamatrixScanIcon from 'vue-material-design-icons/DataMatrixScan'
import { defaultBarcodeScannerOptions } from '../../commons/cordovascan'
import { recognizeLoginBarcode } from '@/libertas/barcodeHandler'
import SecureKeyIcon from 'vue-material-design-icons/ShieldKeyOutline.vue'
import QrCodeModal from '../../components/qrcodereader/QrCodeModal'
export default {
	components: { FeedbackMessage, DatamatrixScanIcon, SecureKeyIcon, QrCodeModal },
	computed:{
		...mapGetters(['orgaName', 'kkLoginName','isMobileAppMode', 'credentialsSupported', 'kkPassword']),
		inputsDisabled() { return this.$store.state.setupState.inputsDisabled},
		withHeaderOffset() { return this.$store.getters.isHeaderFixedTop },
		servicePassword: {
			get () {
        return this.$store.getters.kkPassword
      },
      set (value) {
				this.$store.commit('kkPassword', value)
      }
    },
		interactiveLoginEnabled(){ return this.kkPassword.length > 0 },
	},
	data: function () {
		return {
			credentialsAvailable: true,
			loginWithPassword: this.isMobileAppMode
			}
	},
	methods: {
		allowLoginWithPassword(){
			this.loginWithPassword = true
			setTimeout(() => this.$refs.inputPassword.focus(), 178)
		},
		async loginWebauthn(){
			this.$store.dispatch('dimissFeedbackMessage')
			const result = await this.$store.dispatch('kkLoginWebauthn', this.$store.getters.kkLoginName)
			if(result.isRight){
				this.$bvToast.toast(this.$t('anmeldung.erfolgreich'), {
          			title: this.$t('anmeldung.erfolgreich.toast.title'),
								autoHideDelay: 3200,
								variant:'success'
								})
				this.$store.commit('kkPassword', '')
			}else{
				const rv = result.value
				const credentialsNotAvailable = (rv.response && rv.response.status && rv.response.status === 422)?true:false
				const rvString = new String(rv)
				if( credentialsNotAvailable ){ 
					this.credentialsAvailable = false
					this.loginWithKennwort = true
				}
				if(!credentialsNotAvailable && rvString.indexOf('NotAllowedError') < 0){
					console.log('v', result.value, rv)
					this.$bvToast.toast(JSON.stringify({ err: rvString}), {
          			title: 'Debug Fehler webauthn',
								autoHideDelay: 12000,
								variant:'danger'
								})
				}
			}
		},
	 	async login() { 
		 this.$store.dispatch('dimissFeedbackMessage')
	  const result =	await this.$store.dispatch('kkLogin', { login: this.serviceLoginName, password: this.servicePassword })
		 if(result.isRight){
				this.$store.commit('kkPassword', '')
		 }
		},
		async keyUpOnInput() { 
			const result = await this.$store.dispatch('catchLoginBarcodeInput', this.serviceLoginName)
					
			let rejectLogin = false
			if(result.isRight){
				const serviceBarcode = result.value
				if(this.serviceLoginName !== serviceBarcode.serviceLoginName){
					rejectLogin = true
					this.$bvToast.toast(this.$t('wrong.account.barcode'), {
						title: this.$t('wrong.account.barcode.title'),
						autoHideDelay: 8400,
						variant:'danger'
					})
				}
			}
			if(event.keyCode == 13 && this.setupLoginEnabled && !rejectLogin) {
				setTimeout(() => this.login(), result.isRight?600:0)
			}
		},
		async scanServiceBarcode(){
			if( window.cordova ) {
				window.cordova.plugins.barcodeScanner.scan(
						 (result) => {
								if( result.cancelled || result.text.length === 0 ) return
								this.barcodeReaded(result.text)
						},
						function (error) {
								console.error("Scanning failed: " + error);
						},
						{ ...defaultBarcodeScannerOptions, 
								preferFrontCamera: this.$store.getters.preferFrontCamera,
								prompt : this.$t('scan.service.barcode'), // Android
						}
				);
			}
		},
		scanQRCode(){
			this.$bvModal.show('loginScanQR')
		},
		qrCodefound(decodedString){
			this.$bvModal.hide('loginScanQR')
			this.barcodeReaded(decodedString)
			// this.$bvToast.toast(decodedString, {
      //     			title: 'decoded',
			// 					autoHideDelay: 2000,
			// 					variant:'success'
			// 					})
		},
		barcodeReaded(text){
			const serviceBarcode = recognizeLoginBarcode(text)
			if( serviceBarcode ){ 
				if(this.serviceLoginName !== serviceBarcode.serviceLoginName){
					this.$bvToast.toast(this.$t('wrong.account.barcode'), {
						title: this.$t('wrong.account.barcode.title'),
						autoHideDelay: 5400,
						variant:'danger'
					})
					
				}else{
					this.$store.commit('kkPassword', serviceBarcode.servicePassword)
					this.$store.dispatch('setupDisableInputsLogin')
					this.login()
				}
			}else{
				this.$bvToast.toast(this.$t('service.barcode.nicht.erkannt'), {
				title: this.$t('service.barcode.nicht.erkannt.title'),
				autoHideDelay: 5000,
				variant:'info'
				})
			}
		}
	},
	mounted(){
		//if(this.credentialsSupported) this.loginWebauthn()
	}
	
}
</script>

<style scoped>
.with-header-offset{
	padding-top: 65px;
}
</style>