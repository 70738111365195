<template>
	<div>
		<h5>{{$t('admin.dashboard.header')}}</h5>
		Libertas Center Gesamtzahl: {{ overviewKeyFigures.lc_count_total }}
		Heute aktiv: {{ overviewKeyFigures.lc_active_today }}
		
	<div class="my-2"><b-btn variant="info" @click="toLcRecordsScreen()">zur Administration</b-btn></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed:{
		...mapGetters([ 'overviewKeyFigures' ]),
		countReceiptTypes() { return this.$store.getters.kkReceiptTypes.length },
	},
	methods:{
		toLcRecordsScreen: function(){
      this.$store.dispatch( 'showLcRecords' )
		},
	},

}
</script>

<style>

</style>