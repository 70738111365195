<template>
	<b-alert :variant="getFeedbackVariant" dismissible fade :show="showMessage"  @dismissed="dimissFeedbackMessage">{{getFeedbackMessage}}</b-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	computed:{
		...mapGetters(['getFeedbackMessage', 'getFeedbackVariant']),
		showMessage(){ return this.getFeedbackMessage?true:false}
	},
	methods:{
		dimissFeedbackMessage() { this.$store.dispatch('dimissFeedbackMessage') }
	}
}
		
</script>

<style>

</style>