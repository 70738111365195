const APPLICATION_JSON = 'application/json'
import Either from 'data.either'

export const fetchJson = async (url, method = 'GET', data = null) => {
	try{
		let headers = new Headers()
		headers.append('Accept', APPLICATION_JSON)
		if( data ) headers.append('Content-Type', APPLICATION_JSON)
		
		const body = data?JSON.stringify(data):undefined
		const response = await fetch(url, {
			method,
			mode: 'cors',
			credentials: 'include',
			headers,
			body
		}) 
		const json = await response.json()
		return Either.Right({ response, json })
	} catch (error) {
		console.error('Error: ', error.message)
		return Either.Left({ error })
	}
}

export const parseJwt = (token) => {
	var base64Url = token.split('.')[1]
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
	}).join(''))
	return JSON.parse(jsonPayload)
}

const getAuthHeaderLoginPwd = (login, pwd) => {
  const headers = new Headers()
  headers.append('Accept', 'application/json; charset=UTF-8')
  headers.append('Authorization', "Basic "+ btoa(`${login}:${pwd}`))
  headers.append('Content-type', 'application/json; charset=UTF-8')
  return headers
}

export const fetchJsonWithBasicAuth = async (login = 'login', pwd = 'secret', url, method = 'GET', data = null) => {
	
	try{
		const headers = getAuthHeaderLoginPwd(login, pwd)
		const body = data?JSON.stringify(data):undefined
	
    const response = await fetch(url,
		{
			method,
			credentials: 'include',
			mode: 'cors',
			body,
			headers
		});
		if(!response.ok) return Either.Left({response})
		const json = await response.json()
		return Either.Right({ response, json })
	}catch (error) {
		console.error('Error: ', error)
		return Either.Left({error})
	}
}

export const catchResponse401ResetAuthToken = (ctx, response) => {
	if(response.isLeft && response.value && response.value.response && response.value.response.status){
		const status = response.value.response.status
		if( status === 401 ){
			ctx.dispatch('kkJWT', '')
		}
	}
}

export const fetchJsonWithJWT = async (token = '', url, method = 'GET', data = null) => {
	try{
		let headers = new Headers()
		headers.append('Accept', APPLICATION_JSON)
		headers.append('Authorization', `Bearer ${token}`)
		//headers.append('Access-Control-Allow-Headers', '*')
		if( data ) headers.append('Content-Type', APPLICATION_JSON)
		const body = data?JSON.stringify(data):undefined
		const response = await fetch(url, {
			method,
			mode: 'cors',
			credentials: 'include',
			headers,
			body
		})
		if(!response.ok) return Either.Left({response})
		const json = await response.json()
		return Either.Right({ response, json })
	}catch (error) {
		console.error('Error: ', error)
		return Either.Left({error})
	}
}

export const wait = (ms) => new Promise(resolve => {
	setTimeout(resolve, ms)
})

export const areArraysEqual = (array1, array2) => {
	const array2Sorted = array2.slice().sort()
	const array1Sorted = array1.slice().sort()
	if(array1Sorted.length !== array2Sorted.length) return false
	const equal = array1Sorted.every( (value, index) => value === array2Sorted[index] )
	//console.log('equal', equal)
	return equal
}