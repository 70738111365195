<template>
<div>
	<p class="small text-left">{{$t('confirmed.account.description')}}</p>
	
	<b-form-group class="text-left"
		
		label-align="left"
		label-align-sm="right"
		id="loginFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('lcRecord.confirmed.title')"
		label-for="input-confirmed_account"
				>
		<b-form-checkbox switch class="mx-2 py-2" v-model="confirmed">
			<span class="sr-only">Switch für Fragabe zum Einsatz</span>
		</b-form-checkbox> 
		
	</b-form-group>


	<b-input-group>
		<b-form-text></b-form-text>
	</b-input-group>
</div>
</template>

<script>
export default {
		computed:{
			confirmed: {
			get () {
        return this.$store.getters.nlcdConfirmed?true:false
      },
      set (value) {
				this.$store.dispatch('nlcdConfirmed', value)
      }
		}
		
	},

}
</script>

<style>

</style>