
import Either from 'data.either'
import backend from '../backend'
import i18next from '../i18n/i18n'
import isURL from 'validator/lib/isURL'
import { debounce } from 'debounce'
import { 
	preformatMakeCredReq, preformatGetAssertReq, publicKeyCredentialToJSON } from '../webauthn/webauthnHelper'
import { fetchJson, fetchJsonWithBasicAuth, fetchJsonWithJWT, catchResponse401ResetAuthToken, parseJwt } from '../commons/utils'

const modulState = {

	state: () => ({
		backendURL: '',
		backendURLVerified: false,
		loginName: '',
		password: '',
		jwt: null,
		assertOptions: null,
		inputsDisabled: false,
		
	}),
	getters:{
		isBackendURLVerified: state => state.backendURLVerified,
    isBackendURLSyntaxOk: state => isBackendURLSyntaxOk(state.backendURL),
		kkJWT: state => state.jwt,
		kkLoginName: state => state.loginName,
		backendURL: state => state.backendURL,
		kkPassword: state => state.password,
		kkAssertOptions: state => state.assertOptions,
		setupLoginEnabled: state => {
			return state.loginName && state.password && state.backendURLVerified
		}
	},
	mutations: {
		kkJWT(state, payload) { state.jwt = payload },
		kkPassword(state, payload) { state.password = payload },
		
		kkLoginName(state, payload) { state.loginName = payload },
		backendURLVerified(state, payload) { state.backendURLVerified = payload },
		backendURL(state, payload) { 
			if(state.backendURLVerified !== payload) state.backendURLVerified = false
			state.backendURL = payload
		 },
		kkAssertOptions(state, payload) { state.assertOptions = payload },
		setupSetLoginInputsDisabled(state, payload) {state.inputsDisabled = payload?true:false}
		
  },
	actions: {
		verifyBackendURL: async (ctx) => debouncedVerifyBackendURL(ctx),
		test: (ctx) => console.log('kkState - action'),
		
		proceedLogin: async (ctx) => {
			const response = await backend.getAuthJWT(ctx.state.backendURL, ctx.state.loginName, ctx.state.password)
			if(response.isRight){
				const jwt = response.value.jwt
				//ctx.dispatch('setFeedbackMessage', { message:i18next.t('anmeldung.erfolgreich'), variant:'success', ttl:1200 })
				ctx.commit('setAuthJWT', jwt)
			}else{
				const left = response.value
				if(left.error && left.error.stack && left.error.stack === 'TypeError: Failed to fetch'){
					ctx.commit('feedbackMessage', { message:i18next.t('common.network.error'), variant:'danger' })
				}
				if(left.response && left.response.status && left.response.status === 401){
					ctx.commit('feedbackMessage', { message: i18next.t('login.401') })
				} 
			}
			return response
		},

		kkJWT: (ctx, jwt) => {
			ctx.commit('kkJWT', jwt)
		},

		kkLogin: async (ctx) => {
			const { backendURL, loginName: login, password } = ctx.state
			if(!login) {
				console.log('missing login name')
				return Either.Left('missing login name')
			}
			//console.log('login:', login, 'password:', password)
			const respLogin = await proceedLogin(backendURL, login, password)
			if( respLogin.isLeft ){
				const left = respLogin.value
				if(left.error && left.error.stack && left.error.stack === 'TypeError: Failed to fetch'){
					ctx.commit('feedbackMessage', { message:i18next.t('common.network.error'), variant:'danger' })
				}
				if(left.response && left.response.status && left.response.status === 401){
					ctx.commit('feedbackMessage', { message: i18next.t('login.401') })
				} 
			} else{
				const jwt = respLogin.value.jwt
				ctx.dispatch('kkJWT', jwt)
				//console.log('Login successful, saved into state jwt:', jwt)
			}
			
			return respLogin
		},
		
		kkLoginWebauthn: async (ctx) => {
			const { backendURL, loginName } = ctx.state
			
			if(!loginName) {
				console.log('missing login name')
				return Either.Left('missing login name')
			}
			// ctx.commit('kkLoginName', loginName)
			console.log('login name', loginName)
			
			const respAssertionOptions = await getAssertionOptions(backendURL, loginName)
			if( respAssertionOptions.isLeft ){
				console.log('cannot get respAssertionChallenge')
				return respAssertionOptions
			}
			const assertionOptions = respAssertionOptions.value
			console.log('assertion options', assertionOptions)
			const publicKey = preformatGetAssertReq(assertionOptions)
			
			try{
				const navAssertionResponse = await navigator.credentials.get({ publicKey })
				const assertionResponse = publicKeyCredentialToJSON(navAssertionResponse)
				console.log('assertionResponse', assertionResponse)
				
				const respSendResult = await sendAssertionResponse(backendURL, assertionResponse)
				if(respSendResult.isRight){
					console.log('result:', respSendResult.value)
					const { jwt } = respSendResult.value
					ctx.dispatch('kkJWT', jwt)
				}else{
					return respSendResult
				}

			}catch(err){
				if(err) console.log(err)
				return Either.Left(err)
			}
			return Either.Right('ok')
		},
		
		retrieveCredentials: async (ctx) => {
			const authorizationToken = ctx.getters.kkJWT
			
			ctx.commit('setLoading', true)
			const response = await loadCredentials(ctx, authorizationToken)
			ctx.commit('setLoading', false)

			if(response.isRight){
				ctx.commit('credentials', response.value)
			}
			return response
		},

		updateCredentialNickname: async (ctx, payload) => {
			const authorizationToken = ctx.getters.kkJWT
			ctx.commit('setLoading', true)
			const cred_id = payload.cred_id
			const nickname = payload.nickname
			const backendURL = ctx.getters.backendURL
			const response = await backendUpdateCredentialNickname(backendURL, cred_id, nickname, authorizationToken)
			catchResponse401ResetAuthToken(ctx, response)
			ctx.commit('setLoading', false)
			if( response.isRight){
				// nicht warten
				await ctx.dispatch('retrieveCredentials')
			}
			return response
		},

		deleteCredential: async (ctx, payload) => {
			const cred_id = payload
			const authorizationToken = ctx.getters.kkJWT
			ctx.commit('setLoading', true)
			const backendURL = ctx.getters.backendURL
			const response = await backendDeleteCredential(backendURL, cred_id, authorizationToken)
			catchResponse401ResetAuthToken(ctx, response)
			ctx.commit('setLoading', false)

			if( response.isLeft){
				if(response.value.response.status && response.value.response.status === 404){
					ctx.dispatch('setFeedbackMessage', {message:'Credential zum Löschen nicht gefunden. Bereits gelöscht von anderen Applikation ?', variant:'dark', ttl:2200})

				}else{
				console.warn(response.value)	
				}
			}
			return response
		},

		kkRegisterNewToken: async (ctx, nickname = 'token one') => {
			
			const authToken = ctx.state.jwt
			const backendURL = ctx.getters.backendURL
			const respMakeCredentialChallenge = await fetchCredentialCreationOptions(backendURL, authToken, { nickname })
			catchResponse401ResetAuthToken(ctx, respMakeCredentialChallenge)
			if(respMakeCredentialChallenge.isLeft){
				console.log('cannot get credential challenge')
				return respMakeCredentialChallenge
			}

			const makeCredentialChallenge = respMakeCredentialChallenge.value
			console.log('credential challenge', makeCredentialChallenge)

			const publicKey = preformatMakeCredReq(makeCredentialChallenge)
			try{
				const respCreation = await navigator.credentials.create({ publicKey })
				console.log('created credentials', respCreation)
				const makeCredResponse = publicKeyCredentialToJSON(respCreation)
				const respSendResult = await sendResultCredentialCreation(backendURL, authToken, makeCredResponse)
				catchResponse401ResetAuthToken(ctx, respSendResult)
				if(respSendResult.isRight){
					console.log('result:', respSendResult.value)
					return Either.Right(respSendResult.value)
				}else{
					console.log('nok')
					return respSendResult
				}

			}catch(err){
				if(err) console.log(err)
				return Either.Left(err)
			}
		},
		setupDisableInputsLogin: (ctx) => {
			setTimeout( ()=> ctx.commit('setupSetLoginInputsDisabled', true), 256)
			setTimeout( ()=> ctx.commit('setupSetLoginInputsDisabled', false), 4096)
		}
	
		
	}
}

const isBackendURLSyntaxOk = backendURL => {
	return backendURL.startsWith('http://localhost:8082') || 
		isURL(backendURL) && backendURL.startsWith('https://') && !backendURL.endsWith('/')
	
}

export const getAssertionOptions = async (backendURL, login) => {
	const url = `${backendURL}${backend.URL_LOGIN_WEBAUTHN_OPTIONS}`
	const result = await fetchJson(url, 'POST', { login })
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}else{
			return Either.Left(result.value)
		}
	}
	return result
}

export const sendAssertionResponse = async (backendURL, assertionResponse) => {
	const url = `${backendURL}${backend.URL_LOGIN_WEBAUTHN_ASSERTION_RESULT}`
	const result = await fetchJson(url, 'POST', assertionResponse)
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}


const verifyBackendURL = async (ctx) => {
	const isBackendURLVerified = ctx.getters.isBackendURLVerified
	if(isBackendURLVerified) return

	const backendURL = ctx.getters.backendURL
	const isBackendURLSyntaxOk = ctx.getters.isBackendURLSyntaxOk
	if( !isBackendURLSyntaxOk){
		ctx.commit('backendURLVerified', false)
		await verifySanitizedBackendURLOnSuccessSanitize(ctx)
		return
	}
	const url = `${backendURL}${backend.URL_JWT_PUBLIC_KEY}`
	const result = await fetchJson(url, 'GET')
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			if(json.publicKey && json.pem){
				ctx.commit('backendURLVerified', true)
				return Either.Right(true)
			} 
			else {
				ctx.commit('backendURLVerified', false)
				return Either.Left('unexpected response')
			}
		}
	}
	ctx.commit('backendURLVerified', false)
	return result
}

const verifySanitizedBackendURLOnSuccessSanitize = async (ctx) => {
	const isBackendURLVerified = ctx.getters.isBackendURLVerified
	if(isBackendURLVerified) return
	const backendURL = ctx.getters.backendURL

	console.log('lll', backendURL)
	if(backendURL.length < 5) return
	if(backendURL.endsWith('/')) return

	const sanitizedBackendURL = backendURL.startsWith('localhost')?
		`http://${backendURL}`:`https://${backendURL}`
	
	if(sanitizedBackendURL.startsWith('https://http') || sanitizedBackendURL.startsWith('https:///')) return

	const url = `${sanitizedBackendURL}${backend.URL_JWT_PUBLIC_KEY}`
	const result = await fetchJson(url, 'GET')
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			if(json.publicKey && json.pem){
				ctx.commit('backendURL', sanitizedBackendURL)
				ctx.commit('backendURLVerified', true)
				return Either.Right(true)
			} 
			else {
				ctx.commit('backendURLVerified', false)
				return Either.Left('unexpected response')
			}
		}
	}
	ctx.commit('backendURLVerified', false)
	return result
}

const debouncedVerifyBackendURL = debounce(verifyBackendURL, 1024)

export const proceedLogin = async (backendURL, login, password) => {
	const url = `${backendURL}${backend.URL_GET_AUTH_TOKEN}`
	const result = await fetchJsonWithBasicAuth(login, password, url, 'GET')
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}

const fetchCredentialCreationOptions = async (backendURL, jwt, data) => {
	const url = `${backendURL}${backend.URL_POST_CREDENTIAL_CREATION_OPTIONS}`
	const result = await fetchJsonWithJWT(jwt, url, 'POST', data)
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}

const sendResultCredentialCreation = async (backendURL, jwt, resultCredCreation) => {
	const url = `${backendURL}${backend.URL_POST_REGISTER_CREATED_CREDENTIAL}`
	const result = await fetchJsonWithJWT(jwt, url, 'POST', resultCredCreation)
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}

const backendDeleteCredential = async (backendURL, cred_id, authorizationToken) => {
	const url = `${backendURL}${backend.URL_DELETE_CREDENTIAL}/${cred_id}`
	const result = await fetchJsonWithJWT(authorizationToken, url, 'DELETE')
	// nur status 200 ist interessant 404 - not found
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			console.log('credential deleted. cred_id:', cred_id, 'response:', json)
			return Either.Right(json)
		}
	}
	return result
}

const backendUpdateCredentialNickname = async (backendURL, cred_id, nickname, authorizationToken) => {
	const url = `${backendURL}${backend.URL_POST_CREDENTIAL_NICKNAME}`
	const data = { cred_id, nickname }
	const result = await fetchJsonWithJWT(authorizationToken, url, 'POST', data)
	// nur status 200 ist interessant 404 - not found
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}

/**
 * loads all credentials from backend server
 * @param ctx - context of vuex used for getting and updating of lastUpdateTimestamp
 * @param authorizationToken - authorizationToken
 */
const loadCredentials = async (ctx, authorizationToken) => {
	const backendURL = ctx.getters.backendURL
	const limit = 96
	const url = `${backendURL}${backend.URL_GET_CREDENTIALS}?limit=${limit}`
	//console.debug(url)
	
	const result = await fetchJsonWithJWT(authorizationToken, url, 'GET')
	catchResponse401ResetAuthToken(ctx, result)
	// nur status 200 ist interessant
	if(result.isRight){
		if(result.value.response.status === 200){
			// service_db_clock_timestamp ---> lastUpdateTimestamp
			const credentials = result.value.json
			return Either.Right(credentials)
		}
	}
	return result
}

export default modulState
