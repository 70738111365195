<template>
  <div class="my-2 smart-size">
		<h6>{{$t('allowed.receipt.types')}}</h6>
		<b-jumbotron v-if="!receiptTypes.length">
			{{$t('liste.empty.leer')}}
		</b-jumbotron>	
		<b-container fluid v-else>
			<b-row>
				<b-col xs="12" md="6" xl="4" v-for="receiptType in receiptTypes" :key="receiptType.receipt_type_id" >
					<switch-receipt-type :libertas_center_id="libertas_center_id" :receiptType="receiptType" />
				</b-col>
			</b-row>
		</b-container>
		<transition name="slide-fade" >
		<div class="m-2 text-right"  >
				<b-btn v-if="showButtonSetAlsDefault "
					variant="primary" @click="setAllowedReceiptTypesAsDefault()"  size="sm" >{{$t('set.as.default.allowed.rts')}}</b-btn>
				<b-btn v-if="showBlockButtonSetAlsDefault " block 
					variant="primary" @click="setAllowedReceiptTypesAsDefault()"  size="sm" >{{$t('set.as.default.allowed.rts')}}</b-btn>
		</div>
			</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { areArraysEqual } from '../../commons/utils'

import SwitchReceiptType from './SwitchReceiptType.vue'
export default {
	data: function () {
		return {
			window: {
        width: 0,
        height: 0
      }
		}
	},
	props: ['libertas_center_id'],
	components: { 
		SwitchReceiptType 
	},
	created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
	computed:{
		...mapGetters(['lcRecords', 'receiptTypes', 'defaultAllowedReceiptTypes']),
		lcRecord(){ return this.lcRecords.find( w => w.libertas_center_id === this.libertas_center_id) },
		isSizeXS() { return  this.window.width < 576 },
		allowedReceiptTypes() {
			return (this.lcRecord && this.lcRecord.profile && this.lcRecord.profile.allowed_receipt_types)?
				this.lcRecord.profile.allowed_receipt_types:
				[]
		},
		showButtonSetAlsDefault(){
			return !areArraysEqual([...this.allowedReceiptTypes], 
				[...this.defaultAllowedReceiptTypes]) && !this.isSizeXS
		},
		showBlockButtonSetAlsDefault(){
			return !areArraysEqual([...this.allowedReceiptTypes], 
				[...this.defaultAllowedReceiptTypes]) && this.isSizeXS
		}
	},
	
	methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
		setAllowedReceiptTypesAsDefault(){
			const newDefaultAllowedReceiptTypes = [...this.allowedReceiptTypes]
			this.$store.commit('setDefaultAllowedReceiptTypes', newDefaultAllowedReceiptTypes)
		}
	}
}
</script>

<style>

</style>