
import validator from 'validator'
import i18next from 'i18next'

export const usernameInvalidator = function(str) {
	let error = null
	const maxLength = { max: 128 }
	if(!str){
		error = i18next.t('username.invalid.required')
	}else if(!validator.isLength(str, maxLength)){
		error = i18next.t( 'common.invalid.too.long', maxLength )
	}
	return error
}
export const maxLength256Invalidator = function(str) {
	const maxLength = { max: 256 }
	if(!str){
		return 
	}else if(!validator.isLength(str, maxLength)){
		return i18next.t( 'common.invalid.too.long', maxLength )
	}
}
export const maxLength1024Invalidator = function(str) {
	const maxLength = { max: 1024 }
	if(!str){
		return 
	}else if(!validator.isLength(str, maxLength)){
		return i18next.t( 'common.invalid.too.long', maxLength )
	}
}
export const min3maxLength256Invalidator = function(str) {
	const minMaxLength = { min: 3,  max: 256 }
	if(!str){
		return 
	}else if(!validator.isLength(str, minMaxLength)){
		return i18next.t( 'common.invalid.length', minMaxLength )
	}
}

export const requiredFieldMin3Max256Invalidator = function(str) {
	const minMaxLength = { min: 3, max: 256 }
	if(!str){
		return i18next.t('common.invalid.required')
	}else if(!validator.isLength(str, minMaxLength)){
		return i18next.t( 'common.invalid.length', minMaxLength )
	}
}

export const requiredFieldMax256Invalidator = function(str) {
	const maxLength = { max: 256 }
	if(!str){
		return i18next.t('common.invalid.required')
	}else if(!validator.isLength(str, maxLength)){
		return i18next.t( 'common.invalid.too.long', maxLength )
	}
}

export const plzInvalidator = function(plz) {
	if(!plz){
		return 
	}else if(!validator.isPostalCode(plz, 'DE')){
		return i18next.t( 'invalid.plz' )
	}
}

// Backend Validator

import backend from '../../../backend'
import { catchResponse401ResetAuthToken, fetchJsonWithJWT } from '../../../commons/utils'
import { debounce } from 'debounce'	
	
 const ensureUsernameIsFree = async function( username){
	if(!username || !username.trim()) return
	const libertas_center_id = this.libertas_center_id
	const backendURL = this.$store.getters.backendURL
	const jwt = this.$store.getters.kkJWT
	const url = `${backendURL}${backend.URL_GET_LC_BY_USERNAME}/${encodeURIComponent(username)}`
	const result = await fetchJsonWithJWT(jwt, url, 'GET')
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			let error = null
			if( json[0] && libertas_center_id !== json[0].libertas_center_id ){
				error = i18next.t( 'username.already.in.use' )
			} 
			this.textInvalidFeedback = error?error:''
			this.stateValidation = error?false:true
			
		}
	}
	
}
export const debouncedUsernameValidation = debounce(ensureUsernameIsFree, 1024)