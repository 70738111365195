<template>
<b-jumbotron >
	<p class="lead">{{$t('credentials.liste.leer.text')}}</p>
</b-jumbotron>
</template>

<script>
export default {

}
</script>

<style>

</style>