<template>
	<b-button pill block variant="light" @click="toWaitingRoom()">{{$t('navigate.to.dashboard')}}</b-button>
</template>

<script>
export default {
methods: {
	 toWaitingRoom: function(){
      this.$store.dispatch( 'showDashboard' )
      // this.$store.dispatch('updateDashboardData')
		},
}
}
</script>

<style>

</style>