<template>
<div class="bg-info text-center">
	<div  :class="class4AddWaiterComponent"  :disabled="addDisabled" @click="addLCRecord()" id="tooltip-target-addLcRecord">
		<b-iconstack :font-scale="fontScale4AddWaiterComponent" >
      <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
      <b-icon stacked icon="clipboard-plus" scale="0.5" variant="white"></b-icon>
    </b-iconstack>
	<b-tooltip :placement="toolTipPosition4AddWaiterComponent" variant="primary" target="tooltip-target-addLcRecord" triggers="hover" >
		{{$t('add.lc.record')}}
	</b-tooltip>
	</div>
	<ModalNewLCRecord />
</div>
</template>

<script>
import ModalNewLCRecord from './lcRecord/ModalNewLCRecord'
export default {
	data: function () {
		return {
			addDisabled: false,
			
			}
	},
	components:{  ModalNewLCRecord },
	computed:{
		isInitialPoint(){
			if( this.$store.getters.isViewMainView || this.$store.getters.isViewToBeConfirmed || this.$store.getters.isViewConfirmed){
				return this.$store.getters.lcRecordsToDisplay.length?false:true
			}
			return false
		},
		class4AddWaiterComponent(){ return this.isInitialPoint?'add-waiter-component-initial':'add-waiter-component' },
		
		fontScale4AddWaiterComponent(){
			return this.isInitialPoint?10:5
		},
		toolTipPosition4AddWaiterComponent(){
			return this.isInitialPoint?'bottom':'right'
		}
	},
	methods:{
		addLCRecord: async function(){
			await this.$store.dispatch('initDialogNewLibertasCenter')
			this.$bvModal.show('modal-new-lcRecord')
			
		/*
			this.addDiasbled = true
			this.$store.dispatch('setDisplayFilterStack', viewFilterSet.mainView)
			const result = await this.$store.dispatch('addNewWaiter')
			setTimeout(() => this.addDisabled = false, 1200)
			if(result.isRight){
				const waiter = result.value
				this.$store.commit('waiterIdOfModalWartsAbBarcode', waiter.waiter_id)
				this.$bvModal.show('modalWartsAbIdBarcode')
			}
			*/
		}
	}

}
</script>

<style>

.add-waiter-component {
  bottom: 1.8em;
  right: 1.8em;
  cursor: pointer;
  position: fixed;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  border-radius: 50%;
}
.add-waiter-component-initial {
  top: 68%;
  left:50%;
  
  cursor: pointer;
  position: fixed;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  border-radius: 50%;
  transform: translate(-50%, -4em);
}
</style>