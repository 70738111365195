<template>
	<div>
		<b-alert v-if="password" show variant="info">{{$t('save.account.data.message')}}</b-alert>
		
		<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group
					id="loginFormGroup" label-cols-sm="4" label-cols-lg="3" :label="$t('setup.login.label.login')" 
					:description="'User Id: ' + this.userId"
				>
				<span class="form-control text-left  font-weight-normal">{{ login }}</span>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group 
					id="loginFormGroup" label-cols-sm="4" label-cols-lg="3" :label="$t('setup.login.label.backend.url')" 
				>
				<span class="form-control text-left  font-weight-normal">{{ backendURL }}</span>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import EyeIcon from 'vue-material-design-icons/Eye.vue'
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'
export default {
	props: [ 'showPasswort' ],
	data: function () {
		return {
			isShowPasswort: this.showPasswort?true:false
	
			}
	},
	computed:{
		...mapGetters(['userId', 'backendURL']),
	  login() { return this.$store.getters.kkLoginName },
	  password() { return this.$store.getters.kkPassword },
		contentOfServiceBarcode() { return JSON.stringify({ login: this.login, pwd: this.password, btlrbctype:"aeklbc" }) }
	},
	components: { EyeIcon, QrcodeVue }
	
}
</script>

<style scoped>
.with-header-offset{
	padding-top: 6rem;
}
</style>