import { render, staticRenderFns } from "./ReceiptsEmissionTotal.vue?vue&type=template&id=6a83fa70&"
import script from "./ReceiptsEmissionTotal.vue?vue&type=script&lang=js&"
export * from "./ReceiptsEmissionTotal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports