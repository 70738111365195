import { KEY_PERSISTENT_LOCAL_STORAGE } from './index'
import moment from 'moment'
import i18next from 'i18next'
import Either from 'data.either/lib/either'
import backend from '../backend'
import { fetchJsonWithJWT, catchResponse401ResetAuthToken } from '../commons/utils'


/* Application State: Navigation, Modus, ...  */
const appState = {
	state: () => ({
		feedbackMessage: '',
		preferFrontCamera: false,
		persistServerAuthorisation: false,
		appRuntimeSetupCompleted: false,
		feedbackVariant: 'warning',
		currentScreen: 'dashboard',
		
	}),
	getters:{
		version: () => '1.0.0 dev. build 2021062101',
		authorizationToken: state => state.authJWT,
		preferFrontCamera: state => state.preferFrontCamera,
		getFeedbackVariant: state => state.feedbackVariant,
		getFeedbackMessage: state => state.feedbackMessage,
		persistServerAuthorisation: state => state.persistServerAuthorisation,
		
		hotDurationOptions: () => optionsStatusHotMinutes,

		isScreenDashboard:  (state, getters, rootState) => getters.isSetupCompleted && state.currentScreen === 'dashboard',
		isScreenSettings: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'settings',
		isScreenSettingsWaitingRoom: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'settingsWaitingRoom',
		isScreenImressum: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'impressum',
		isScreenPrivacyPolicy: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'privacypolicy',
		isScreenLcRecords: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'lcRecords',
		isScreenReceipts: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'receipts',
		isScreenAbout: ( state, getters ) => getters.isSetupCompleted && state.currentScreen === 'about',
    
	},
	mutations: {
		persistServerAuthorisation (state, payload) { state.persistServerAuthorisation = payload },
		appRuntimeSetupCompleted (state, payload) { state.appRuntimeSetupCompleted = payload },
		
		currentScreen (state, payload) { state.currentScreen = payload },
		preferFrontCamera (state, payload) { state.preferFrontCamera = payload },
	
		feedbackMessage(state, payload = {}) { 
			const { message = '', variant = 'warning' } = payload
			state.feedbackMessage = message 
			state.feedbackVariant = variant
		}
	
	},
	actions: {
		setupAppRuntimeSettingsCopmleted: (ctx) => {
			if(!ctx.state.appRuntimeSetupCompleted){
				ctx.commit('appRuntimeSetupCompleted', true)
				if(	window.cordova && 'browser' !== window.cordova.platformId){
					// mobile App -> per Default save Authorisation
					ctx.commit('persistServerAuthorisation', true)
				}
			}
		},
		persistServerAuthorisation: (ctx, payload) => { ctx.commit('persistServerAuthorisation', payload?true:false) },
		preferFrontCamera: (ctx, payload) => { ctx.commit('preferFrontCamera', payload?true:false) },
		showDashboard: (ctx, payload) => { ctx.commit('currentScreen', 'dashboard') },
		showSettings: (ctx, payload) => { ctx.commit('currentScreen', 'settings') },
		showLcRecords: (ctx, payload) => { ctx.commit('currentScreen', 'lcRecords') },
		showReceipts: (ctx, payload) => { ctx.commit('currentScreen', 'receipts') },
		showImpressum: (ctx, payload) => { ctx.commit('currentScreen', 'impressum') },
		showPrivacyPolicy: (ctx, payload) => { ctx.commit('currentScreen', 'privacypolicy') },
		showAbout: (ctx, payload) => { ctx.commit('currentScreen', 'about') },

		setFeedbackMessage: (ctx, payload = {}) => { 
			ctx.commit('feedbackMessage', payload)
			const {ttl = 0} = payload
			if(ttl) setTimeout(() => { ctx.commit('feedbackMessage', {}) }, ttl)
		},
		dimissFeedbackMessage: ctx => { ctx.commit('feedbackMessage', {}) },
		test: (ctx) => console.log('appState - action'),
		resetApp: (ctx) => {
			ctx.commit('restoreFactorySettings') 
			setTimeout( () => {
				window.localStorage.removeItem(KEY_PERSISTENT_LOCAL_STORAGE)
				window.location.reload() }, 2 * 1000)

		},
		changePassword: async (ctx, payload) => {
			const authToken = ctx.getters.kkJWT
			const backendURL = ctx.rootGetters.backendURL
			const resp = await changePasswordOnBackend(backendURL, authToken, payload)
			catchResponse401ResetAuthToken(ctx, resp)
			if(resp.isRight){
				if(resp.value.affectedRows === 1){
					ctx.dispatch('setFeedbackMessage', {message: i18next.t('feedback.message.change.pwd.ok'), variant:'success', ttl:12200})
				}
			}
			return resp
		}
	
	}
}

export default appState

const changePasswordOnBackend = async (backendURL, authorizationToken, data) => {
	const url = `${backendURL}${backend.URL_POST_CHANGE_PASSWORD}`
	const result = await fetchJsonWithJWT(authorizationToken, url, 'POST', data)
	// nur status 200 ist interessant 404 - not found
	if(result.isRight){
		if(result.value.response.status === 200){
			const json = result.value.json
			return Either.Right(json)
		}
	}
	return result
}
const 	optionsStatusHotMinutes = [
	{ value: null, text: i18next.t('please.select.an.option') },
	{ value: 0, text: '0'},
	{ value: 1, text: '1'},
	{ value: 2, text: '2'},
	{ value: 3, text: '3'},
	{ value: 4, text: '4'},
	{ value: 5, text: '5'},
	{ value: 10, text: '10'},
	{ value: 20, text: '20'}
]