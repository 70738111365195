<template>
<div class="warteraum-panel bg-light py-2" v-bind:class="{ 'is_sticky': isSticky}" 
v-bind:style="{ 'margin-top': marginTop + 'px'}">
  <b-container>
    <SearchPanel class="d-md-none" />
	  <b-nav pills class="smartSize" >
      <MainView />
      <ToBeConfirmed />
      <Confirmed />
      <!-- <Papierkorb /> -->
      <Ansicht />
    </b-nav>
   <WarningMaxAllowedLcRecords class="my-2" /> 
   <WarningMaxAllowedLcRecordsInView class="my-2" /> 
   </b-container>
</div>
</template>

<script>
import SearchPanel from './SearchPanel'
import WarningMaxAllowedLcRecords from './WarningMaxAllowedLcRecords'
import WarningMaxAllowedLcRecordsInView from './WarningMaxAllowedLcRecordsInView'

import MainView from './panel/MainView'
import Confirmed from './panel/Confirmed'
import ToBeConfirmed from './panel/ToBeConfirmed'
import Papierkorb from './panel/Papierkorb'
import Ansicht from './panel/Ansicht'
export default {
  components: { WarningMaxAllowedLcRecords, WarningMaxAllowedLcRecordsInView, 
  SearchPanel, MainView, ToBeConfirmed, Confirmed, Papierkorb, Ansicht},
  computed:{
    // isSticky(){ return this.$store.getters.scrollPosition > 100}
    isHeaderFocedTop(){ return this.$store.getters.isHeaderFixedTop },
    scrollPosition(){ return this.$store.getters.scrollPosition },
    isSticky(){ return this.$store.getters.isLcRecordsPanelSticky },
      
    marginTop() { 
      const scrollPosition = this.scrollPosition
      
      if( !this.isSticky ) {
        return 0
      }
      if( !this.isHeaderFocedTop && this.isSticky) return -82
      // const offset = -scrollPosition
      // const marginTop = -77 + offset
      // console.log(marginTop)
      // return marginTop }
      return 0
    }
  }
 
}
</script>

<style>
.is_sticky{
  
  position: fixed;
  width: 100%;
  
  z-index: 3;
}
/* Small devices (landscape phones, 544px and up) */
@media (max-width: 543px) {  
  .smartSize {font-size:0.7rem;} /*1rem = 16px*/
}
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  .smartSize {font-size:0.8rem;} /*1rem = 16px*/
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  .smartSize {font-size:0.9rem;} /*1rem = 16px*/
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .smartSize {font-size:1rem;} /*1rem = 16px*/
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  .smartSize {font-size:1rem;} /*1rem = 16px*/    
}
  
</style>