<template>
 <b-container class="app-panel bg-white container">
	<p class="lead mb-4">{{$t('setup.login.text')}}</p>

	<FeedbackMessage/>
	<b-form v-on:submit.prevent="">
		<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group
					id="loginFormGroup"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('setup.login.label.backend.url')"
					label-for="input-login"
				>
					<b-form-input id="input-backend-url" :state="backendURLInputState" :disabled="inputsDisabled" 
					@keyup="keyUpOnInput()" v-model="backendURL" ref="backendURLInput" 
						:placeholder="$t('setup.login.label.backend.url.placeholder')"/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group
					id="loginFormGroup"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('setup.login.label.login')"
					label-for="input-login"
				>
					<b-form-input id="input-login" :disabled="inputsDisabled" @keyup="keyUpOnInput()" 
						v-model="serviceLoginName" ref="loginInput" :placeholder="$t('setup.login.label.login.placeholder')"/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="pwd"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('setup.login.label.password')"
					:description="$t('setup.login.tipp')"
					label-for="input-password"
				>
					<b-form-input id="input-password" @keyup="keyUpOnInput()" :disabled="inputsDisabled" v-model="servicePassword" autocomplete="false" type="password" />
				</b-form-group>
			</b-col>
		</b-row>
	</b-form>
		<b-row class="d-flex" align-h="between">
		<div class="col-md-6 col-lg-4 col-sm-12 text-left my-2">
			<b-button block class="btn d-none" :disabled="!isWebauthnLoginEnabled" v-if="credentialsSupported"
			variant="primary" @click="loginWebauthn()">{{$t('button.login.with.token')}}<secure-key-icon :size="21" /></b-button>
			
		</div>
		<div class="col text-right my-2">
		<b-button class="mb-2" variant="light" @click="cancel()">{{$t('button.cancel')}}</b-button>
		<b-button class="mb-2 mr-4 ml-2 mr-2"  v-if="isMobileAppMode" variant="secondary" @click="scanServiceBarcode()">
			 <datamatrix-scan-icon :size="18" class="text-light" />
		</b-button>
		<b-button class="mb-2 mr-4 ml-2 mr-2"  v-else variant="info" @click="scanQRCode()">
				<datamatrix-scan-icon :size="18" title="Scan QRCode" class="text-light" />
		</b-button>
		<b-button class="mb-2 ml-2" :disabled="!setupLoginEnabled" variant="primary" @click="login()">{{$t('welcome.intro.button.login')}}</b-button>
		</div>
	</b-row>
	 <QrCodeModal id="loginScanQR" :onBarcodeDetected="qrCodefound" 
		:subject="$t('login.qrcode.scan.subject')" :title="$t('login.qrcode.scan.title')"></QrCodeModal>
 </b-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import FeedbackMessage from '@/components/FeedbackMessage'

import DatamatrixScanIcon from 'vue-material-design-icons/DataMatrixScan'
import { defaultBarcodeScannerOptions } from '../../commons/cordovascan'
import SecureKeyIcon from 'vue-material-design-icons/ShieldKeyOutline.vue'
import { recognizeLoginBarcode } from '@/libertas/barcodeHandler'
import QrCodeModal from '../qrcodereader/QrCodeModal'
export default {
	computed:{
		...mapGetters([ 'setupLoginEnabled', 'isMobileAppMode', 'credentialsSupported', 'isBackendURLSyntaxOk', 'isBackendURLVerified' ]),
		inputsDisabled() { return this.$store.state.setupState.inputsDisabled},
		backendURLInputState() { return this.isBackendURLVerified?true:null },
		backendURL: {
      get () {
        return this.$store.getters.backendURL
      },
      set (value) {
        this.$store.commit('backendURL', value)
      }
    },
		serviceLoginName: {
      get () {
        return this.$store.getters.kkLoginName
      },
      set (value) {
        this.$store.commit('kkLoginName', value)
      }
    },
		servicePassword: {
      get () {
        return this.$store.getters.kkPassword
      },
      set (value) {
        this.$store.commit('kkPassword', value)
      }
		},
		isWebauthnLoginEnabled() { return this.$store.getters.kkLoginName.length >= 1 && 
			this.isBackendURLSyntaxOk }
	},
	components: { FeedbackMessage, DatamatrixScanIcon, SecureKeyIcon, QrCodeModal },
	methods: {
		scanQRCode(){
			this.$bvModal.show('loginScanQR')
		},
		qrCodefound(decodedString){
			this.$bvModal.hide('loginScanQR')
			this.barcodeReaded(decodedString)
			// this.$bvToast.toast(decodedString, {
      //     			title: 'decoded',
			// 					autoHideDelay: 2000,
			// 					variant:'success'
			// 					})
		},
		barcodeReaded(text){
			const loginBarcode = recognizeLoginBarcode(text)
			if( loginBarcode ){ 
					this.$store.commit('backendURL', loginBarcode.backend)
					this.$store.commit('kkLoginName', loginBarcode.login)
					this.$store.commit('kkPassword', loginBarcode.pwd)
					this.$store.dispatch('setupDisableInputsLogin')
					this.login()
			}else{
				this.$bvToast.toast(this.$t('login.barcode.nicht.erkannt'), {
				title: this.$t('login.barcode.nicht.erkannt.title'),
				autoHideDelay: 5000,
				variant:'info'
				})
			}
		},
		async loginWebauthn(){
			const result = await this.$store.dispatch('kkLoginWebauthn')
			if(result.isRight){
				this.$bvToast.toast('scheint zu funktionieren', {
          			title: 'Ok',
								autoHideDelay: 5000,
								variant:'success'
								})
				this.$store.commit('kkPassword', '')
				this.$store.commit('setSetupStep', 'completed')				
			}else{
				const rv = result.value
				const credentialsNotAvailable = (rv.response && rv.response.status && rv.response.status === 422)?true:false
				const rvString = new String(rv)
				if( credentialsNotAvailable ){
					this.$bvToast.toast(this.$t('no.credentials.found'), {
          			title: this.$t('no.credentials.found.title'),
								autoHideDelay: 7800,
								variant:'info'
								})
				}
				if(!credentialsNotAvailable && rv.indexOf('NotAllowedError') < 0){
					console.log('v', result.value, rv)
					this.$bvToast.toast(JSON.stringify({ err: rvString}), {
          			title: 'Debug Entwicklung',
								autoHideDelay: 12000,
								variant:'danger'
								})
				}
			}
		},

  	async login() { 
		 this.$store.dispatch('dimissFeedbackMessage')
		 const result =	await this.$store.dispatch('kkLogin')
		 if(result.isRight){
				this.$store.commit('kkPassword', '')
				this.$store.commit('setSetupStep', 'completed')
		 }
		},

		async scanServiceBarcode(){
			if( window.cordova ) {
				window.cordova.plugins.barcodeScanner.scan(
						 (result) => {
							if( result.cancelled || result.text.length === 0 ) return
							this.barcodeReaded(result.text)
						},
						function (error) {
								console.error("Scanning failed: " + error);
						},
						{ ...defaultBarcodeScannerOptions, 
								preferFrontCamera: this.$store.getters.preferFrontCamera,
								prompt : this.$t('scan.service.barcode'), // Android
						}
				);
			}

		},
		cancel() { 
			this.$store.dispatch('dimissFeedbackMessage')
			this.$store.commit('setSetupStep', 'init') 
		},
		async keyUpOnInput() { 
			const result = this.$store.dispatch('catchLoginBarcodeInput') 
			if(event.keyCode == 13 && this.setupLoginEnabled) {
				setTimeout(() => this.login(), result.isRight?600:0)
			}
			this.$store.dispatch('verifyBackendURL') 
		},
	},
	mounted() {
		setTimeout(() => this.$refs.loginInput.focus(), 128)
	}
}
</script>
