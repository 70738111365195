<template>
	<div>
		<h5>{{$t('libertas.center.dashboard.header')}}</h5>
		<p class="text-muted">
		Insgesamt Tests: XXXX Heute: yy/zz Positive: FF Negative: KK
		</p>
		<b-jumbotron>
		bitte die Libertas Center App dafür nutzen
		</b-jumbotron>
		
	<div class="my-2"><b-btn disabled variant="info">zu den Tests/Belege </b-btn></div>
	</div>
</template>

<script>
export default {
	computed:{
		countReceiptTypes() { return this.$store.getters.kkReceiptTypes.length },
	},
	methods:{
		toPublicKeysScreen: function(){
      this.$store.dispatch( 'showPublicKeys' )
		},
	},

}
</script>

<style>

</style>