import backend from '../backend'
import Either from 'data.either/lib/either'
import { fetchJson, fetchJsonWithJWT } from '../commons/utils'
import { decode, encode } from '../commons/base64url-arraybuffer'


/**
 * Decodes arrayBuffer required fields.
 */
export const preformatMakeCredReq = (makeCredReq) => {
	makeCredReq.challenge = decode(makeCredReq.challenge);
	makeCredReq.user.id = decode(makeCredReq.user.id);
	makeCredReq.excludeCredentials.forEach(cred => cred.id = decode(cred.id))

	return makeCredReq
}

export const preformatGetAssertReq = (assertionChallenge) => {
	assertionChallenge.challenge = decode(assertionChallenge.challenge);
    
	for(let allowCred of assertionChallenge.allowCredentials) {
			allowCred.id = decode(allowCred.id);
	}

	return assertionChallenge
}

/**
 * Converts PublicKeyCredential into serialised JSON
 * @param  {Object} pubKeyCred
 * @return {Object}            - JSON encoded publicKeyCredential
 */
export const publicKeyCredentialToJSON = (pubKeyCred) => {
	if(pubKeyCred instanceof Array) {
			const arr = [];
			for(let i of pubKeyCred)
					arr.push(publicKeyCredentialToJSON(i));

			return arr
	}

	if(pubKeyCred instanceof ArrayBuffer) {
			return encode(pubKeyCred)
	}

	if(pubKeyCred instanceof Object) {
			const obj = {};

			for (const key in pubKeyCred) {
					obj[key] = publicKeyCredentialToJSON(pubKeyCred[key])
			}

			return obj
	}

	return pubKeyCred
}