<template>
	<b-modal size="lg" id="modal-new-lcRecord" :title="$t('modal.new.lcrecord.title')" @show="onShow()">
		
		<p class="text-center lead my-4">{{$t('create.new.account.lead.message')}}</p>
		<b-form v-on:submit.prevent="">
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="nlcd-username" label-class="font-weight-bold"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('lcRecord.username.title')"
					:description="$t('lcRecord.username.placeholder')"
					label-for="nlcdInputUsername"
				>
				<b-form-input ref="nlcdInputUsername" id="nlcdInputUsername" v-model="userName"
				:placeholder="$t('lcRecord.username.placeholder')" :state="nlcdUsernameValidationState"
				 type="text"   aria-describedby="invalid-feedback-username valid-feedback-username" />
				<b-form-invalid-feedback id="invalid-feedback-username">{{ nlcdUsernameInvalidFeedback }}</b-form-invalid-feedback>
				<b-form-valid-feedback id="valid-feedback-username">{{$t('lcrecord.username.is.free')}}</b-form-valid-feedback>
				</b-form-group>
			</b-col>
		</b-row><b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="new-pwd" label-class="font-weight-bold"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('lcRecord.title.title')"
					:description="$t('lcRecord.title.description')"
					label-for="input-title" 
				>
				<b-form-input id="input-title"  v-model="title" :state="nlcdTitleValidationState"
				:placeholder="$t('lcRecord.title.placeholder')"
					autocomplete="false" type="text" aria-describedby="invalid-feedback-title" />
				<b-form-invalid-feedback id="invalid-feedback-title">{{ nlcdTitleInvalidFeedback }}</b-form-invalid-feedback>
				
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="plz"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('lcRecord.plz.title')"
					:description="$t('lcRecord.plz.description')"
					label-for="input-title" 
				>
				<b-form-input id="input-title"  v-model="plz" autocomplete="false" type="number" 
				:placeholder="$t('lcRecord.plz.placeholder')"
				:state="nlcdPlzValidationState" aria-describedby="invalid-feedback-plz" />
				<b-form-invalid-feedback id="invalid-feedback-plz">{{ nlcdPlzInvalidFeedback }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="contact"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('lcRecord.contact.title')"
					:description="$t('lcRecord.contact.description')"
					label-for="input-contact" 
				>
				<b-form-input id="input-contact"  v-model="contact" autocomplete="false" type="text"
				  :placeholder="$t('lcRecord.contact.placeholder')"
					:state="nlcdContactValidationState" aria-describedby="invalid-feedback-contact" />
				<b-form-invalid-feedback id="invalid-feedback-contact">{{ nlcdContactInvalidFeedback }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="description"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('lcRecord.description.title')"
					:description="$t('lcRecord.description.description')"
					label-for="input-description" 
				>
				<b-form-textarea v-model="description" 
      		rows="5" max-rows="8" 
					:placeholder="$t('lcRecord.contact.placeholder')"
					:state="nlcdDescriptionValidationState"
					aria-describedby="invalid-feedback-description" >
						</b-form-textarea>
				<b-form-invalid-feedback id="invalid-feedback-description">{{ nlcdDescriptionInvalidFeedback }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
		<confirmed-switch />
		<!-- weakened -->
		<!-- <admin-account-switch /> -->
			
	</b-form>
	<b-row class="d-flex" >
		<div class="col text-left my-2">
			<b-alert variant="warning" dismissible fade :show="showMessage"  @dismissed="dimissFeedbackMessage">{{feedbackMessage}}</b-alert>
		</div>
	</b-row>
	<template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('forget')">{{$t('close')}}</b-button>
			<b-button :disabled="working || !nlcdCreateEnabled" variant="primary"
			 @click="createAccount(hide)">{{$t('button.create.lcrecord')}}</b-button>
  </template>
	</b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AdminAccountSwitch from './AdminAccountSwitch.vue'
import ConfirmedSwitch from './ConfirmedSwitch.vue'
export default {
	data: function () {
		return {
		feedbackMessage: '',
		working: false	
		}
	},
	components: { AdminAccountSwitch, ConfirmedSwitch },
	computed:{
		 ...mapGetters(['nlcdUsernameInvalidFeedback', 'nlcdTitleInvalidFeedback', 'nlcdPlzInvalidFeedback',
		 'nlcdContactInvalidFeedback', 'nlcdDescriptionInvalidFeedback',
     'nlcdCreateEnabled',
		 'nlcdUsernameValidationState', 'nlcdTitleValidationState', 'nlcdPlzValidationState',
		 'nlcdContactValidationState', 'nlcdDescriptionValidationState']),
		userName: {
				get () {
					return this.$store.getters.nlcdUsername
				},
				set (value) {
					this.$store.dispatch('nlcdUsername', value)
			}
    },
		title: {
				get () {
					return this.$store.getters.nlcdTitle
				},
				set (value) {
					this.$store.dispatch('nlcdTitle', value)
			}
    },
		plz: {
				get () {
					return this.$store.getters.nlcdPlz
				},
				set (value) {
					this.$store.dispatch('nlcdPlz', value)
			}
    },
		contact: {
				get () {
					return this.$store.getters.nlcdContact
				},
				set (value) {
					this.$store.dispatch('nlcdContact', value)
			}
    },
		description: {
				get () {
					return this.$store.getters.nlcdDescription
				},
				set (value) {
					this.$store.dispatch('nlcdDescription', value)
			}
    },
		showMessage() { return this.feedbackMessage?true:false },
		changeEnabled() { return this.newPwd.length > 5 && this.oldPwd.length > 5 && this.newPwd === this.newPwdAgain && this.newPwd !== this.oldPwd }
	},
	methods:{
		onShow() {
			this.feedbackMessage = '',
			this.working = false,
			setTimeout(() => {
				if(this.$refs.nlcdInputUsername) {
					this.$refs.nlcdInputUsername.select()
						this.$refs.nlcdInputUsername.focus()
				}
			}, 128)
		},

		dimissFeedbackMessage() { this.feedbackMessage = '' },

		async createAccount(hide) { 
			this.working = true
			const resp = await this.$store.dispatch('nlcdCreateAccount')
			this.working = false
			if( resp.isLeft ){
				this.feedbackMessage = this.$t('create.new.account.nok')
				await this.$store.dispatch('nlcdEmsureUsernameIsAvailable')
			}else{
				hide()
			}
			
	 	}
	}
}
</script>

<style>

</style>