<template>
<div>
	<p class="small text-left">{{$t('lcrecords.orphaned.items.description')}}</p>
	
	<b-form-group class="text-left"
		
		label-align="left"
		label-align-sm="right"
		id="loginFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('lcrecords.show.orphaned.label', { countOrphaned })"
		label-for="input-hotDuration"
				>
		<b-form-checkbox switch class="mx-2 py-2" v-model="showOrphaned">
			<span class="sr-only">Switch for following text input</span>
		</b-form-checkbox> 
		
	</b-form-group>


	<b-input-group>
		<b-form-text></b-form-text>
	</b-input-group>
</div>
</template>

<script>
export default {
		computed:{
			countOrphaned(){ return this.$store.getters.orphanedLcRecords.length },
			showOrphaned: {
			 get () {
        return this.$store.state.lcState.filterOrphaned?false:true
      },
      set (value) {
				this.$store.dispatch('setShowOrphanedLcRecords', value)
      }
		}
		
	},

}
</script>

<style>

</style>