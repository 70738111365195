<template>
	<b-alert  show v-if="showWarningMaxAllowedLcRecords" variant="warning">
		{{$t('warning.max.allowed.waiters')}}
	</b-alert>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters(['showWarningMaxAllowedLcRecords'])
	}

}
</script>

<style>

</style>