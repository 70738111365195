export const filterDimissed = (lcRecord) => lcRecord.dimissed?false:true
export const filterDeleted = (lcRecord) => lcRecord.deleted?false:true
export const filterConfirmed = (lcRecord) => lcRecord.confirmed?false:true

export const filterOrphaned = (lcRecord) => lcRecord.password?true:false
export const filterPinned = (lcRecord) => lcRecord.pinned?false:true

export const filterDeletedOnly = (lcRecord) => lcRecord.deleted?true:false
export const filterConfirmedOnly = (lcRecord) => lcRecord.confirmed?true:false
export const filterDimissedOnly = (lcRecord) => lcRecord.dimissed?true:false

export default { filterDeletedOnly, filterDimissed, filterDeleted, 
	filterOrphaned, filterPinned, filterDimissedOnly,
	filterConfirmedOnly, filterConfirmed }