<template>
	<div class="GNVPVGB-S-a">
		 <div class="GNVPVGB-fb-a">
			 <div class="GNVPVGB-fb-b"></div> 
			 <div class="GNVPVGB-fb-c"></div>
			</div> 
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	computed: mapGetters(['loadingSubject' ]),
}
</script>
