<template>
	<div class="d-inline-block">
		<pin-icon v-if="isPinned" class="text-warning" @click="togglePinned()" />
		<pin-off-outline-icon  v-else class="text-muted pinOff" @click="togglePinned()" />
	</div>
</template>

<script>
import PinIcon from 'vue-material-design-icons/Pin.vue'
import PinOffOutlineIcon from 'vue-material-design-icons/PinOffOutline.vue'
import { mapGetters, mapState } from 'vuex'
export default {
		components : { PinIcon, PinOffOutlineIcon },
		props: ['libertas_center_id'],
		computed: {
			...mapGetters(['lcRecords']),
		  lcRecord(){ return this.lcRecords.find( r => r.libertas_center_id === this.libertas_center_id) },
			isPinned(){ return this.lcRecord && this.lcRecord.pinned?true:false}
		},
		methods:{
			togglePinned(){ 
				const lcRecord = this.lcRecord
				const pinned = !this.isPinned
				this.$store.commit('mergeLcRecord', {...lcRecord, pinned}) }
		}
}
</script>

<style>

.pinOff{
	opacity: 0.7;
}

</style>