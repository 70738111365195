
const videoDevicesState = {
	state: () => ({
		videoDevices: [],
		videoDeviceId: null,
		torchOn: false
		
	}),
	getters:{
		videoDevices: state => state.videoDevices,
		videoDeviceId: state => state.videoDeviceId,
		videoDeviceTorchOn: state => state.videoDeviceTorchOn
    
	},
	mutations: {
		videoDevices (state, payload) { state.videoDevices = payload },
		videoDeviceId (state, payload) { state.videoDeviceId = payload },
		videoDeviceTorchOn (state, payload) { state.videoDeviceTorchOn = !!payload }
	},
	actions: {
		setVideoDeviceId: (ctx, payload) => { ctx.commit('videoDeviceId', payload) },
		setupVideoDevices: async (ctx) => { 
			const devices = (await navigator.mediaDevices.enumerateDevices()).filter(
				({ kind }) => kind === "videoinput"
			)
			
			ctx.commit('videoDevices', devices) 
		},
	}
}
export default videoDevicesState
