/* Setup State -> transient */
import Either from 'data.either'
import { recognizeLoginBarcode } from '../libertas/barcodeHandler'
import backend from '../backend'
import i18next from '../i18n/i18n'


const modulState = {

	state: () => ({
		currentStep: 'init',
	}),
	getters:{
		isSetupCompleted: state => state.currentStep === 'completed',
		setupStep: state => state.currentStep,
	
	},
	mutations: {
		setSetupStep(state, payload) { state.currentStep = payload }
  },
	actions: {
		test: (ctx) => console.log('setupState - action'),
		catchLoginBarcodeInput: async ( ctx, fixedLoginAndBackendURL = false) => {
			let loginBarcode = ''

			if(!fixedLoginAndBackendURL){
				const backendURL = ctx.rootGetters.backendURL
				if(backendURL.length > 18) loginBarcode = recognizeLoginBarcode(backendURL)

				if( !loginBarcode ){
					const serviceLoginName = ctx.rootGetters.kkLoginName
					if(serviceLoginName.length > 18){
						loginBarcode = recognizeLoginBarcode(serviceLoginName)
					}
				}
			}
			const servicePassword = ctx.rootGetters.kkPassword
			if(!loginBarcode && servicePassword.length > 18) loginBarcode = recognizeLoginBarcode(servicePassword)
			
			if( loginBarcode ){
				 loginBarcodeRecognized(ctx, loginBarcode, fixedLoginAndBackendURL)
				 return Either.Right(loginBarcode)
			}else{
				return Either.Left({})
			}
			
		}
	
	
	}
}
const loginBarcodeRecognized = (ctx, serviceBarcode, fixedLoginAndBackendURL = false) => {
	if(! fixedLoginAndBackendURL){
		ctx.commit('backendURL', serviceBarcode.backend)
		ctx.commit('kkLoginName', serviceBarcode.login)
	} 
	ctx.commit('kkPassword', serviceBarcode.pwd)
	ctx.dispatch('setupDisableInputsLogin')
	
}
export default modulState