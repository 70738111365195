<template>
<div class="app-panel text-left" >
	<div class="h6">{{$t('register.credential.title')}}</div>
	<b-container>
			<b-row >
			<b-col lg class="mt-2 text-sm-right text-left">
				<b-form-group
					id="addBehandlerFormGroup"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('register.credential.nickname.label')"
					label-for="input-login"
				>
					<b-form-input @keyup="keyUpOnInput($event)" :state="validation" v-model="newCredentialNickname" ref="newCredentialNickname" 
					:placeholder="$t('register.credential.nickname.placeholder')"/>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="text-right">
			<b-col>
				<b-button :disabled="!isNewCredentianNickanmeValid" @click="startTokenRegistration()" variant="primary">
					{{$t('cred.start.registration.button')}} <secure-key-icon :size="21" />
				</b-button>
			</b-col>
		</b-row>	
	</b-container>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SecureKeyIcon from 'vue-material-design-icons/ShieldKeyOutline.vue'
import Either from 'data.either/lib/either'
export default {
	components: { SecureKeyIcon },
	computed:{
		...mapGetters([ 'credentials' ]),
		newCredentialNickname: {
			get () {
				return this.$store.getters.newCredentialNickname
			},
			set (value) {
				this.$store.dispatch('newCredentialNickname', value)
			},
		},
		isNewCredentianNickanmeValid: function(){
			const nickname = this.$store.getters.newCredentialNickname.trim()
				return this.newCredentialNickname.length > 0 && this.credentials.findIndex((cred) => cred.nickname === nickname) < 0
		},
		validation: function() {
			const nickname = this.$store.getters.newCredentialNickname.trim()
			if (nickname.length === 0 || this.credentials.findIndex((cred) => cred.nickname === nickname) < 0) return null
			return false
    }
	},
	methods: {
		
		keyUpOnInput: function(event){
			if(event.keyCode == 13 && this.isNewCredentianNickanmeValid) {
				this.startTokenRegistration()
				
			}
		},
		async startTokenRegistration(){
			const result = await this.$store.dispatch('kkRegisterNewToken', this.$store.getters.newCredentialNickname)
			
			if(result.isRight){
				this.$store.dispatch('newCredentialNickname', '')
				this.$bvToast.toast(this.$t('new.token.registered'), {
						title: this.$t('new.token.registered.title'),
						autoHideDelay: 12000,
						variant:'success'
						})
				await this.$store.dispatch('retrieveCredentials')
			}else{
				const rv = result.value
				const rvString = new String(rv)
					if(rvString.indexOf('credentials already registered with the relying party') > 0){
						this.$bvToast.toast(this.$t('new.token.already.registered'), {
          			title: this.$t('new.token.already.registered.title'),
								autoHideDelay: 12000,
								variant:'warning'
								})
					}else
					{
						console.log('v', result.value, rv)
						this.$bvToast.toast(JSON.stringify({ err: rvString}), {
          			title: 'Debug Entwicklung',
								autoHideDelay: 12000,
								variant:'danger'
								})
				}
			}
			setTimeout(() => this.$refs.newCredentialNickname.focus(), 256)
		}
	},
	mounted() {
		//setTimeout(() => this.$refs.addBehandlerInput.focus(), 256)
	}
}
</script>

<style>

</style>