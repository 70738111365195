<template>
<div>
	<h5>{{$t('passwordless.login.title')}}</h5>
	<h6>{{$t('credentials.liste.title')}}</h6>
	<transition name="slide-fade" >
			<KeineCredentials v-if="keineCredentials" />
	</transition>
	
	<table v-if="!keineCredentials" class="table table-sm table-striped border-bottom">
		<tbody>
			<CredentialRow v-for="credential in credentials" :key="credential.cred_id" :credential="credential"/>
		</tbody>
	</table>
	
	<RegisterNewCredential v-if="credentialsSupported" />
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CredentialRow from '@/components/client/CredentialRow'
import KeineCredentials from '@/components/client/KeineCredentials'
import RegisterNewCredential from '@/components/client/RegisterNewCredential'
export default {
	computed:{
		...mapGetters(['credentials', 'credentialsSupported']),
		keineCredentials: function() { 
			return this.$store.getters.credentials.length === 0
		}

	},
	components: { CredentialRow, KeineCredentials, RegisterNewCredential },
	async mounted(){
		this.$store.dispatch('retrieveCredentials')
	}
}
</script>

<style>
/* .table tr	td{	vertical-align: middle; } */
</style>