<template>
<div>
	<div class="text-right">
		<b-button variant="light" @click="$bvModal.show('modal-change-pwd')">{{$t('modal.change.password.title')}}</b-button>
	</div>
<b-modal size="lg" id="modal-change-pwd" :title="$t('modal.change.password.title')">
		<b-alert variant="warning" dismissible fade :show="showMessage"  @dismissed="dimissFeedbackMessage">{{feedbackMessage}}</b-alert>
		<p class="text-center lead my-4">{{$t('change.pwd.lead.message')}}</p>
		<b-form v-on:submit.prevent="">
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="old-pwd"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('label.old.pwd')"
					:description="$t('change.pwd.old.pwd.tipp')"
					label-for="input-password-old"
				>
				<b-form-input id="input-password-old" v-model="oldPwd" autocomplete="false" type="password" />
				</b-form-group>
			</b-col>
		</b-row><b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="new-pwd"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('label.new.pwd')"
					:description="$t('change.pwd.new.pwd.tipp')"
					label-for="input-password-new"
				>
				<b-form-input id="input-password-new"  v-model="newPwd" autocomplete="false" type="password" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="form-group">
			<b-col lg class="text-sm-right text-left">
				<b-form-group
					id="new-pwd-again"
					label-cols-sm="4" label-cols-lg="3" 
					:label="$t('label.new.pwd.again')"
					:description="$t('change.pwd.new.pwd.again.tipp')"
					label-for="input-password-new-again"
				>
				<b-form-input id="input-password-new-again" v-model="newPwdAgain" autocomplete="false" type="password" />
				</b-form-group>
			</b-col>
		</b-row>
	</b-form>
	<b-row class="d-flex" >
		<div class="col text-right my-2">
		</div>
	</b-row>
	<template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('forget')">{{$t('close')}}</b-button>
			<b-button :disabled="!changeEnabled" variant="primary" @click="changePassword(hide)">{{$t('modal.change.password.title')}}</b-button>
  </template>
	</b-modal>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			feedbackMessage: '',
			oldPwd: '',
			newPwd: '',
			newPwdAgain: '',
			}
	},
	computed:{
		showMessage() { return this.feedbackMessage?true:false },
		changeEnabled() { return this.newPwd.length > 5 && this.oldPwd.length > 5 && this.newPwd === this.newPwdAgain && this.newPwd !== this.oldPwd }
	},
	methods:{
		dimissFeedbackMessage() { this.feedbackMessage = '' },
		async changePassword(hide) { 
			const resp = await this.$store.dispatch('changePassword', { oldPassword: this.oldPwd, newPassword: this.newPwd })
			if( resp.isLeft ){
				this.feedbackMessage = this.$t('feedback.message.change.pwd.nok')
			}else{
				hide()
			}
			
	 	}
	}
}
</script>

<style>

</style>