<template>
<div v-bind:class="{'d-inline-block ' : !isEditMode}">
	<div  :title="title" v-if="!isEditMode" @click="toEditMode()">
		<div :class="viewClass"> 
		<key-variant-icon :size="21"/> {{viewTextValue}} 
		</div>
	</div>
	
	<span v-if="isEditMode" class="my-1">
		<div class="form-group required row">
			<div class="col-sm-12">
				<!-- @keyUp="onUserPasswortKeyUp($event)" -->
				<b-form @submit.stop.prevent>
					
      			<b-input ref="editVaueInput" type="text" :placeholder="placeholder" 
							@keyup="editOnKeyUp($event)" @blur="updateValue()" aria-describedby="input-help-block" v-model="editValue"></b-input>
						<b-form-text id="input-help-block">{{placeholder}}</b-form-text>
					
				</b-form>
			</div>
		</div>
	</span>
</div> 		

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import KeyVariantIcon from 'vue-material-design-icons/KeyVariant.vue'
export default {
	components:{ KeyVariantIcon },
	data: function () {
		return {
			isEditMode: false,
			editValue: this.viewValue?this.viewValue:''
			}
	},
	props: ['cred_id', 'viewClass', 'viewTextMaxLength'],
	computed:{
		 ...mapGetters(['credentials']),
		 credential(){ return this.credentials.find( c => c.cred_id === this.cred_id) },
		 viewValue(){ return this.credential?this.credential.nickname:'' },
		 viewTextValue(){ 
			 const shortedText = this.viewTextMaxLength?this.credential.nickname.substring(0, this.viewTextMaxLength):this.viewValue
			 return shortedText.length < this.viewValue.length?shortedText+'...':shortedText },

		 title(){ return this.$t(`credential.nickname.title`) },
		 placeholder(){ return this.$t(`credential.nickname.placeholder`) }
	},
	methods:{
		toEditMode(){
			this.editValue = this.viewValue
			this.isEditMode = true
			setTimeout(() => {
			if(this.$refs.editVaueInput) {
					this.$refs.editVaueInput.select()
					this.$refs.editVaueInput.focus()
				}
			}, 128)
		},
		editOnKeyUp(event){
			if( event.keyCode === 13 && (!this.isTextArea || event.ctrlKey)) {
			//if( event.keyCode === 13 ) {
				console.log(event)
				this.updateValue()
			}
			if( event.keyCode === 27 ) this.isEditMode = false
		},
		updateValue(){
			if(!this.isEditMode) return
			let newValue = this.editValue?this.editValue.trim():''
			if( !newValue ) newValue = this.$t('credential.nickname.noname')
			this.isEditMode = false

			this.$store.dispatch('updateCredentialNickname', { cred_id: this.cred_id, nickname: newValue})
			
		}
	}

}
</script>

<style>

</style>