<template>
<div>
	
	<p class="small text-left">{{$t('lcrecord.option.hot.duration.description')}}</p>
	<b-form-group 
		label-align="left"
		label-align-sm="right"
		id="loginFormGroup"
		label-cols-sm="4" label-cols-lg="3" 
		:label="$t('hot.status.option.label')"
		label-for="input-hotDuration"
				>
		<b-form-select id="input-hotDuration" v-model="hotDurationMinutes" :options="options"
	 		aria-describedby="input-hotDuration-help"></b-form-select>
		<b-form-text id="input-hotDuration-help">{{$t('lcrecord.option.hot.duration.help')}}.</b-form-text>
	</b-form-group>

</div>
</template>

<script>
export default {
	computed:{
		
		options() { return this.$store.getters.hotDurationOptions },
		hotDurationMinutes: {
			 get () {
        return this.$store.getters.hotDurationMinutesLcRecords
      },
      set (value) {
				this.$store.dispatch('hotDurationMinutesLcRecords', value)
      }
		}
	}

}
</script>

<style>

</style>