<template>
 <b-container class="app-panel bg-white container">

	<FeedbackMessage/>
	<h5>{{$t('terms.of.use.header')}}</h5>
	<p class="lead my-4">{{$t('terms.of.use.description')}}</p>
	<div class="text-left">
		<terms-of-use-content-de />
	</div>
	
	<div class="col text-right my-2">
		<b-button class="my-2 mx-2" @click="decline()" variant="secondary" >{{$t('terms.of.use.button.decline')}}</b-button>
		<b-button class="my-2 mx-2" @click="accept()" variant="primary" >{{$t('terms.of.use.button.accept')}}</b-button>
	</div>

 </b-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import FeedbackMessage from '@/components/FeedbackMessage'
import TermsOfUseContentDe from './TermsOfUseContentDe.vue'
export default {
  components: { TermsOfUseContentDe },

	methods: {
  	
		decline() { 
			this.$store.commit('setSetupStep', 'init') 
		},
		accept() { 
			this.$store.commit('setSetupStep', 'registerNewAccount') 
		}
	}

}
</script>
