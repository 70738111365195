<template>
<div class="text-right">
  <b-button-toolbar :justify="true" key-nav aria-label="Toolbar with button groups">
    
		<b-button-group v-if="hasPassword" size="sm" class="m-1">
    	<b-button variant="info" @click="showAnmeldedaten()"><eye-icon :size="21" /> Anmeldedaten</b-button>
    </b-button-group>

    <b-button-group size="sm" class="m-1">
      <b-button :disabled="buttonsDisabled" v-if="isFreigegeben" variant="secondary" @click="revokeConfirmed()">{{$t('button.revoke.confirmed')}}</b-button>
      <b-button :disabled="buttonsDisabled" v-else variant="secondary" @click="grantConfirmed()">{{$t('button.grant.confirmed')}}</b-button>

      <!-- weakened edition
				<b-button :disabled="buttonsDisabled" v-if="isAdmin" variant="secondary"  @click="revokeAdmin()">{{$t('button.revoke.admin')}}</b-button> 
				<b-button :disabled="buttonsDisabled" v-else variant="warning"  @click="askToGrantAdmin()">{{$t('button.grant.admin')}}</b-button> 
			-->

    </b-button-group>
    <b-button-group size="sm" class="m-1">
			<b-button :disabled="buttonsDisabled" variant="warning" @click="askToResetPasswod()">{{$t('button.reset.password')}}</b-button>
      <b-button :disabled="buttonsDisabled" variant="danger" @click="askToRemoveAccount()">
				{{$t('button.delete.account')}}</b-button>
    </b-button-group>
  </b-button-toolbar>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import EyeIcon from 'vue-material-design-icons/Eye.vue'
import moment from 'moment'
import { nanoid } from 'nanoid'
export default {
	data: function () {
		return {
			buttonsDisabled: false
		}
	},
	props: ['libertas_center_id'],
	components: { EyeIcon  },
	computed:{
		...mapGetters(['lcRecords']),
		lcRecord(){ return this.lcRecords.find( w => w.libertas_center_id === this.libertas_center_id) },
		isFreigegeben(){ return this.lcRecord.confirmed?true:false },
		hasPassword(){ return this.lcRecord.password?true:false },
		isAdmin(){ return this.lcRecord.admin_account===1?true:false },
	},
	methods:{
		showAnmeldedaten(){
			this.$store.commit('modalAnmeldedatenLcId', this.libertas_center_id)
			this.$bvModal.show('modal-anmeldedaten')
		},
		async grantConfirmed(){
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id, patch: { confirmed: moment().toISOString() } }
			console.log(payload)
			const updateResult  = await this.$store.dispatch('patchAttributeOfLcRecord', payload )
			if(updateResult.isRight){
				const param = { title }
				this.$bvToast.toast(this.$t('grant.confirmed.message', param ), {
          			title: this.$t('grant.confirmed.title'),
								autoHideDelay: 6000,
								variant:'success'
								})
				this.$store.dispatch('updateLcRecords')
			}
			
		},
		async revokeConfirmed(){
			this.disableButtons()
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id, patch: { confirmed: null } }
			const updateResult  = await this.$store.dispatch('patchAttributeOfLcRecord', payload )
			if(updateResult.isRight){
				const param = { title }
				this.$bvToast.toast(this.$t('revoke.confirmed.message', param ), {
          			title: this.$t('revoke.confirmed.title'),
								autoHideDelay: 6000,
								variant:'warning'
								})
				this.$store.dispatch('updateLcRecords')
			
			}

		},
		async grantAdmin(){
			this.disableButtons()
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id, patch: { admin_account: 1 } }
			const updateResult  = await this.$store.dispatch('patchAttributeOfLcRecord', payload )
			if(updateResult.isRight){
				const param = { title }
				this.$bvToast.toast(this.$t('grant.admin.message', param ), {
          			title: this.$t('grant.admin.title'),
								autoHideDelay: 9000,
								variant:'warning'
								})
				this.$store.dispatch('updateLcRecords')
			
			}
		},
		async revokeAdmin(){
			this.disableButtons()
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id, patch: { admin_account: 0 } }
			const updateResult  = await this.$store.dispatch('patchAttributeOfLcRecord', payload )
			if(updateResult.isRight){
				const param = { title }
				this.$bvToast.toast(this.$t('revoke.admin.message', param ), {
					title: this.$t('revoke.admin.title'),
								autoHideDelay: 6000,
								variant:'success'
								})
				this.$store.dispatch('updateLcRecords')
			}
		},
		async askToGrantAdmin(){
			const { title } = this.lcRecord
			const param = { title }
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('confirm.grant.admin.text', param), 
				{
          title: this.$t('confirm.grant.admin.title'),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'warning',
					bodyVariant: 'warning',
          okTitle: this.$t('button.grant.admin'),
          cancelTitle: this.$t('cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
			if(decision) this.grantAdmin()
		},
		async askToResetPasswod(){
			const { title } = this.lcRecord
			const param = { title }
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('confirm.reset.password.text', param), 
				{
          title: this.$t('confirm.reset.password.title'),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'warning',
					bodyVariant: 'warning',
          okTitle: this.$t('button.reset.password'),
          cancelTitle: this.$t('cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
			if(decision) this.resetPassword()
		},
		async resetPassword(){
			this.disableButtons()
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id, patch: { password: nanoid().substring(0, 9) } }
			const updateResult  = await this.$store.dispatch('patchAttributeOfLcRecord', payload )
			if(updateResult.isRight){
				const patch = payload.patch
				patch.libertas_center_id = libertas_center_id
				this.$store.commit('mergeLcRecord', patch) // Passwort wird nicht von Server übertrage. Direct speichern
				const param = { title }
				this.$bvToast.toast(this.$t('password.reset.ok.message', param ), {
					title: this.$t('password.reset.ok.title'),
								autoHideDelay: 9000,
								variant:'info'
								})
				this.$store.dispatch('updateLcRecords')
			}
		},
		async askToRemoveAccount(){
			const { title } = this.lcRecord
			const param = { title }
			const decision = await this.$bvModal.msgBoxConfirm(this.$t('confirm.delete.account.text', param), 
				{
          title: this.$t('confirm.delete.account.title'),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('button.delete.account'),
          cancelTitle: this.$t('cancel'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
			if(decision) this.removeAccount()
		},
		async removeAccount(){
			console.log('mache ich ....')	
			this.disableButtons()
			const { title, libertas_center_id } = this.lcRecord
			const payload = { libertas_center_id }
			const deleteResult  = await this.$store.dispatch('deleteLcRecord', payload )
			if(deleteResult.isRight){
				const patch = payload.patch
				const param = { title }
				this.$bvToast.toast(this.$t('account.deleted.text', param ), {
					title: this.$t('account.deleted.title'),
					autoHideDelay: 9000,
					variant:'success'	})
			}else{
				if( 0 === deleteResult.value.affectedRows ){
					// bereits gelöscht (wahrscheinlich)
					const param = { title }
					this.$bvToast.toast(this.$t('account.already.deleted.text', param ), {
						title: this.$t('account.already.deleted.title'),
						autoHideDelay: 9000,
						variant:'info' })
				}
			}
		},
		disableButtons(){
			this.buttonsDisabled = true
			setTimeout( () => { this.buttonsDisabled = false }, 1024) 
		},
	}

}
</script>

<style>

</style>