
/*
const exampleLoginBarcode={
	login:'lcOne',
	backend: 'https://mtk.libertas.org/backend',
	pwd:'secret',
	bctp:'lcSetup'
}

{"login":"lcOne","pwd":"secret","bctp":"lcSetup","backend":"https://wetnotes.t-provider.de/libertas/backend"}

*/
export const recognizeLoginBarcode = (barcode) => {
	if( !barcode || barcode.length < 20) return null
	try{
		const json = JSON.parse(barcode)
		if(json && json.bctp && json.login && json.pwd && json.backend && json.bctp === 'lcSetup'){
			return { login: json.login, pwd: json.pwd, backend: json.backend}
		}
	}catch(err){
		return null
	}
	return null
}
