<template>
	<div class="with-header-offset">
		<h4>{{$t('settings')}}</h4>

			<div class="app-panel container bg-white my-4 py-4">
			<FeedbackMessage/>
			<div class="h4 my-4">{{$t('settings.security')}}</div>
			<AccountData />
			<change-password />
			<PersistServerAuthorisation class="my-4" />
			<div v-if="isAdminAccount">
				<div class="h4 my-4">{{$t('extended.settings')}}</div>

				<status-hot-options-lc-records class="my-4" />
				<show-orphaned-lc-records-switch class="my-4" />
			</div>
			<div>

			<!-- <b-button v-b-toggle.collapse-1 variant="info">{{$t('fido2.settings')}}</b-button>
			<b-collapse id="collapse-1" class="mt-2">
					<Credentials class="my-4" />
			</b-collapse> -->

</div>
			<to-dashboard class="my-4" />
			</div>
	</div>
</template>

<script>
import PreferFrontCameraSwitch from './PreferFrontCameraSwitch'
import PersistServerAuthorisation from './PersistServerAuthorisationSwitch'
import StatusHotOptionsLcRecords from './StatusHotOptionsLcRecords'
import Credentials from '@/components/webauthn/Credentials.vue'
import FeedbackMessage from '@/components/FeedbackMessage'
import AccountData from '@/components/AccountData'
import ToDashboard from './ToDashboard.vue'
import ChangePassword from './ChangePassword.vue'
import ShowOrphanedLcRecordsSwitch from './ShowOrphanedLcRecordsSwitch.vue'
import { mapGetters } from 'vuex'
export default {
	data: function () {
		return {
		}
	},
	computed:{
		...mapGetters(['isAdminAccount' ]),
	},
	methods:{
		
	},
	components: { 
		StatusHotOptionsLcRecords, PersistServerAuthorisation,	FeedbackMessage, AccountData,
		Credentials, ToDashboard, PreferFrontCameraSwitch, ChangePassword, 
		ShowOrphanedLcRecordsSwitch, StatusHotOptionsLcRecords
	}
}
</script>

<style scoped>
.with-header-offset{
	padding-top: 6rem;
}
</style>