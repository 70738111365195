<template>
 <b-jumbotron class="bg-white">
		 <!-- <div class="display-4">{{$t('welcome')}}</div> -->
    <p class="lead mb-4">{{$t('welcome.intro.text')}}</p>
    <b-button variant="primary" @click="weiter()">{{$t('welcome.intro.button.weiter')}}</b-button>
 </b-jumbotron>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	components: { },
	methods: {
  weiter() {
    this.$store.commit('setSetupStep', 'login')
  }
}
}
</script>